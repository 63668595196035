export const vars = {
    primaryFont: "inter, sans-serif",
    titleFont:"inter display, sans-serif",
    colorPrimary: "#3779E1",
    bodyBgColor: '#F2F4F7',
    borderRadius: 8,
    titleFontColor: '#1D2939',
    paperShadow:'0px 0px 1px rgba(0, 0, 0, 0.3), 0px 20px 24px -4px rgba(16, 24, 40, 0.08), 0px 8px 8px -4px rgba(16, 24, 40, 0.03)',
    grey400:'#98A2B3',
    topbarShadow:'0px 1px 5px rgba(41, 85, 115, 0.2);',
    drawerWidth:228,
    sidebarBg:'#222739',
    menuItemSelectedBg:'#475467',
    captionColor:'#667085',
    iconPrimaryColor:'#667085',
    buttonOutlinedColor:"#344054",
    buttonOutlinedBorderColor: "#D0D5DD",
    buttonOutlinedHoverBg: '#F9FAFB',
    darkTextColor: '#101828',
    labelColor: '#1D2939',
    radioBorderColor: '#D0D5DD',
    darkBlue: "#184EA2",
    lightBlue: "#E2ECFB",
    mediumBlue: "#548CE5",
    inputPlaceholderColor: '#676C74',
    whiteColor: '#fff',
    inputTextColor: '#676C74',
    dropdownChipColor: '#EAECF0',
    dropdownBorderColor: '#8DB2EE',
    popperBorderColor: '#ECEDEE',
    badgeBg: '#C6D9F6',
    drodownDetailBg: '#FCFCFD',
    dropdownHeadingColor: '#373A3E',
    dropdownTextColor: '#A9ACB2',
    primary800: '#0C2751',
    gray200: '#EAECF0',
    warning600: '#DC6803',
    gray600: '#475467',
    gray50: '#F9FAFB',
    gray300: '#D0D5DD',
    gray400: '#98A2B3',
    gray500: '#667085'
};
