/* tslint:disable */
/* eslint-disable */
/**
 * SCKAN Composer API
 * SCKAN Composer API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError, operationServerMap } from './base';

/**
 * 
 * @export
 * @enum {string}
 */

export const ActionEnum = {
    AssignUser: 'assign_user',
    AssignTag: 'assign_tag',
    WriteNote: 'write_note',
    ChangeStatus: 'change_status',
    AssignPopulationSet: 'assign_population_set'
} as const;

export type ActionEnum = typeof ActionEnum[keyof typeof ActionEnum];


/**
 * 
 * @export
 * @interface AlertType
 */
export interface AlertType {
    /**
     * 
     * @type {number}
     * @memberof AlertType
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof AlertType
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof AlertType
     */
    'uri': string;
}
/**
 * 
 * @export
 * @interface AnatomicalEntity
 */
export interface AnatomicalEntity {
    /**
     * 
     * @type {number}
     * @memberof AnatomicalEntity
     */
    'id': number;
    /**
     * 
     * @type {AnatomicalEntityMeta}
     * @memberof AnatomicalEntity
     */
    'simple_entity': AnatomicalEntityMeta;
    /**
     * 
     * @type {AnatomicalEntityIntersection}
     * @memberof AnatomicalEntity
     */
    'region_layer': AnatomicalEntityIntersection;
    /**
     * 
     * @type {string}
     * @memberof AnatomicalEntity
     */
    'synonyms': string;
}
/**
 * 
 * @export
 * @interface AnatomicalEntityIntersection
 */
export interface AnatomicalEntityIntersection {
    /**
     * 
     * @type {number}
     * @memberof AnatomicalEntityIntersection
     */
    'id': number;
    /**
     * 
     * @type {AnatomicalEntityMeta}
     * @memberof AnatomicalEntityIntersection
     */
    'layer': AnatomicalEntityMeta;
    /**
     * 
     * @type {AnatomicalEntityMeta}
     * @memberof AnatomicalEntityIntersection
     */
    'region': AnatomicalEntityMeta;
}
/**
 * 
 * @export
 * @interface AnatomicalEntityMeta
 */
export interface AnatomicalEntityMeta {
    /**
     * 
     * @type {number}
     * @memberof AnatomicalEntityMeta
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof AnatomicalEntityMeta
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof AnatomicalEntityMeta
     */
    'ontology_uri': string;
}
/**
 * 
 * @export
 * @interface AssignPopulationSet
 */
export interface AssignPopulationSet {
    /**
     * The bulk action to perform.
     * @type {ActionEnum}
     * @memberof AssignPopulationSet
     */
    'action': ActionEnum;
    /**
     * ID of the population set.
     * @type {number}
     * @memberof AssignPopulationSet
     */
    'population_set_id': number;
}


/**
 * 
 * @export
 * @interface AssignTags
 */
export interface AssignTags {
    /**
     * The bulk action to perform.
     * @type {ActionEnum}
     * @memberof AssignTags
     */
    'action': ActionEnum;
    /**
     * List of tag IDs to add.
     * @type {Array<number>}
     * @memberof AssignTags
     */
    'add_tag_ids': Array<number>;
    /**
     * List of tag IDs to remove.
     * @type {Array<number>}
     * @memberof AssignTags
     */
    'remove_tag_ids': Array<number>;
}


/**
 * 
 * @export
 * @interface AssignUser
 */
export interface AssignUser {
    /**
     * The bulk action to perform.
     * @type {ActionEnum}
     * @memberof AssignUser
     */
    'action': ActionEnum;
    /**
     * ID of the user to assign.
     * @type {number}
     * @memberof AssignUser
     */
    'user_id': number;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const AvailableTransitionsBc5Enum = {
    Draft: 'draft',
    ComposeNow: 'compose_now',
    InProgress: 'in_progress',
    ToBeReviewed: 'to_be_reviewed',
    Revise: 'revise',
    Rejected: 'rejected',
    NpoApproved: 'npo_approved',
    Exported: 'exported',
    Deprecated: 'deprecated',
    Invalid: 'invalid'
} as const;

export type AvailableTransitionsBc5Enum = typeof AvailableTransitionsBc5Enum[keyof typeof AvailableTransitionsBc5Enum];


/**
 * Adds nested create feature
 * @export
 * @interface BaseConnectivityStatement
 */
export interface BaseConnectivityStatement {
    /**
     * 
     * @type {number}
     * @memberof BaseConnectivityStatement
     */
    'id': number | null;
    /**
     * 
     * @type {string}
     * @memberof BaseConnectivityStatement
     */
    'knowledge_statement'?: string;
    /**
     * 
     * @type {Array<Tag>}
     * @memberof BaseConnectivityStatement
     */
    'tags': Array<Tag>;
    /**
     * 
     * @type {User}
     * @memberof BaseConnectivityStatement
     */
    'owner': User;
    /**
     * 
     * @type {number}
     * @memberof BaseConnectivityStatement
     */
    'owner_id'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof BaseConnectivityStatement
     */
    'state': string;
    /**
     * 
     * @type {string}
     * @memberof BaseConnectivityStatement
     */
    'modified_date': string;
    /**
     * 
     * @type {boolean}
     * @memberof BaseConnectivityStatement
     */
    'has_notes': boolean;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const BlankEnum = {
    Empty: ''
} as const;

export type BlankEnum = typeof BlankEnum[keyof typeof BlankEnum];


/**
 * @type BulkAction
 * @export
 */
export type BulkAction = { action: 'assign_population_set' } & AssignPopulationSet | { action: 'assign_tag' } & AssignTags | { action: 'assign_user' } & AssignUser | { action: 'change_status' } & ChangeStatus | { action: 'write_note' } & WriteNote;

/**
 * 
 * @export
 * @interface BulkActionResponse
 */
export interface BulkActionResponse {
    /**
     * 
     * @type {number}
     * @memberof BulkActionResponse
     */
    'updated_count': number;
}
/**
 * 
 * @export
 * @interface ChangeStatus
 */
export interface ChangeStatus {
    /**
     * The bulk action to perform.
     * @type {ActionEnum}
     * @memberof ChangeStatus
     */
    'action': ActionEnum;
    /**
     * The new status.
     * @type {string}
     * @memberof ChangeStatus
     */
    'new_status': string;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const CircuitTypeEnum = {
    Sensory: 'SENSORY',
    Motor: 'MOTOR',
    Intrinsic: 'INTRINSIC',
    Projection: 'PROJECTION',
    Anaxonic: 'ANAXONIC'
} as const;

export type CircuitTypeEnum = typeof CircuitTypeEnum[keyof typeof CircuitTypeEnum];


/**
 * Connectivity Statement
 * @export
 * @interface ConnectivityStatement
 */
export interface ConnectivityStatement {
    /**
     * 
     * @type {number}
     * @memberof ConnectivityStatement
     */
    'id': number | null;
    /**
     * 
     * @type {number}
     * @memberof ConnectivityStatement
     */
    'sentence_id'?: number;
    /**
     * 
     * @type {Sentence}
     * @memberof ConnectivityStatement
     */
    'sentence': Sentence;
    /**
     * 
     * @type {string}
     * @memberof ConnectivityStatement
     */
    'knowledge_statement'?: string;
    /**
     * 
     * @type {Array<Tag>}
     * @memberof ConnectivityStatement
     */
    'tags': Array<Tag>;
    /**
     * 
     * @type {Array<Provenance>}
     * @memberof ConnectivityStatement
     */
    'provenances'?: Array<Provenance>;
    /**
     * 
     * @type {User}
     * @memberof ConnectivityStatement
     */
    'owner': User;
    /**
     * 
     * @type {number}
     * @memberof ConnectivityStatement
     */
    'owner_id'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ConnectivityStatement
     */
    'state': string;
    /**
     * 
     * @type {Array<AvailableTransitionsBc5Enum>}
     * @memberof ConnectivityStatement
     */
    'available_transitions': Array<AvailableTransitionsBc5Enum>;
    /**
     * 
     * @type {Array<AnatomicalEntity>}
     * @memberof ConnectivityStatement
     */
    'origins'?: Array<AnatomicalEntity>;
    /**
     * 
     * @type {Array<ViaSerializerDetails>}
     * @memberof ConnectivityStatement
     */
    'vias'?: Array<ViaSerializerDetails>;
    /**
     * 
     * @type {Array<DestinationSerializerDetails>}
     * @memberof ConnectivityStatement
     */
    'destinations'?: Array<DestinationSerializerDetails>;
    /**
     * 
     * @type {number}
     * @memberof ConnectivityStatement
     */
    'phenotype_id'?: number | null;
    /**
     * 
     * @type {Phenotype}
     * @memberof ConnectivityStatement
     */
    'phenotype': Phenotype;
    /**
     * 
     * @type {ProjectionPhenotype}
     * @memberof ConnectivityStatement
     */
    'projection_phenotype': ProjectionPhenotype;
    /**
     * 
     * @type {number}
     * @memberof ConnectivityStatement
     */
    'projection_phenotype_id'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ConnectivityStatement
     */
    'journey': string;
    /**
     * 
     * @type {string}
     * @memberof ConnectivityStatement
     */
    'entities_journey': string;
    /**
     * 
     * @type {ConnectivityStatementLaterality}
     * @memberof ConnectivityStatement
     */
    'laterality'?: ConnectivityStatementLaterality | null;
    /**
     * 
     * @type {ConnectivityStatementProjection}
     * @memberof ConnectivityStatement
     */
    'projection'?: ConnectivityStatementProjection | null;
    /**
     * 
     * @type {ConnectivityStatementCircuitType}
     * @memberof ConnectivityStatement
     */
    'circuit_type'?: ConnectivityStatementCircuitType | null;
    /**
     * 
     * @type {Array<Specie>}
     * @memberof ConnectivityStatement
     */
    'species'?: Array<Specie>;
    /**
     * 
     * @type {number}
     * @memberof ConnectivityStatement
     */
    'sex_id'?: number | null;
    /**
     * 
     * @type {Sex}
     * @memberof ConnectivityStatement
     */
    'sex': Sex;
    /**
     * 
     * @type {number}
     * @memberof ConnectivityStatement
     */
    'population_id'?: number | null;
    /**
     * 
     * @type {PopulationSet}
     * @memberof ConnectivityStatement
     */
    'population': PopulationSet;
    /**
     * 
     * @type {boolean}
     * @memberof ConnectivityStatement
     */
    'has_statement_been_exported': boolean;
    /**
     * 
     * @type {Array<number>}
     * @memberof ConnectivityStatement
     */
    'forward_connection'?: Array<number>;
    /**
     * 
     * @type {string}
     * @memberof ConnectivityStatement
     */
    'apinatomy_model'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ConnectivityStatement
     */
    'additional_information'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ConnectivityStatement
     */
    'modified_date': string;
    /**
     * 
     * @type {boolean}
     * @memberof ConnectivityStatement
     */
    'has_notes': boolean;
    /**
     * 
     * @type {string}
     * @memberof ConnectivityStatement
     */
    'statement_preview': string;
    /**
     * 
     * @type {Array<any>}
     * @memberof ConnectivityStatement
     */
    'errors': Array<any>;
    /**
     * 
     * @type {GraphState}
     * @memberof ConnectivityStatement
     */
    'graph_rendering_state'?: GraphState | null;
    /**
     * 
     * @type {Array<StatementAlert>}
     * @memberof ConnectivityStatement
     */
    'statement_alerts'?: Array<StatementAlert>;
}
/**
 * @type ConnectivityStatementCircuitType
 * @export
 */
export type ConnectivityStatementCircuitType = BlankEnum | CircuitTypeEnum;

/**
 * @type ConnectivityStatementLaterality
 * @export
 */
export type ConnectivityStatementLaterality = BlankEnum | LateralityEnum;

/**
 * @type ConnectivityStatementProjection
 * @export
 */
export type ConnectivityStatementProjection = BlankEnum | ProjectionEnum;

/**
 * Connectivity Statement
 * @export
 * @interface ConnectivityStatementUpdate
 */
export interface ConnectivityStatementUpdate {
    /**
     * 
     * @type {number}
     * @memberof ConnectivityStatementUpdate
     */
    'id': number | null;
    /**
     * 
     * @type {number}
     * @memberof ConnectivityStatementUpdate
     */
    'sentence_id'?: number;
    /**
     * 
     * @type {Sentence}
     * @memberof ConnectivityStatementUpdate
     */
    'sentence': Sentence;
    /**
     * 
     * @type {string}
     * @memberof ConnectivityStatementUpdate
     */
    'knowledge_statement'?: string;
    /**
     * 
     * @type {Array<Tag>}
     * @memberof ConnectivityStatementUpdate
     */
    'tags': Array<Tag>;
    /**
     * 
     * @type {Array<Provenance>}
     * @memberof ConnectivityStatementUpdate
     */
    'provenances'?: Array<Provenance>;
    /**
     * 
     * @type {User}
     * @memberof ConnectivityStatementUpdate
     */
    'owner': User;
    /**
     * 
     * @type {number}
     * @memberof ConnectivityStatementUpdate
     */
    'owner_id'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ConnectivityStatementUpdate
     */
    'state': string;
    /**
     * 
     * @type {Array<AvailableTransitionsBc5Enum>}
     * @memberof ConnectivityStatementUpdate
     */
    'available_transitions': Array<AvailableTransitionsBc5Enum>;
    /**
     * 
     * @type {Array<number>}
     * @memberof ConnectivityStatementUpdate
     */
    'origins': Array<number>;
    /**
     * 
     * @type {Array<ViaSerializerDetails>}
     * @memberof ConnectivityStatementUpdate
     */
    'vias'?: Array<ViaSerializerDetails>;
    /**
     * 
     * @type {Array<DestinationSerializerDetails>}
     * @memberof ConnectivityStatementUpdate
     */
    'destinations'?: Array<DestinationSerializerDetails>;
    /**
     * 
     * @type {number}
     * @memberof ConnectivityStatementUpdate
     */
    'phenotype_id'?: number | null;
    /**
     * 
     * @type {Phenotype}
     * @memberof ConnectivityStatementUpdate
     */
    'phenotype': Phenotype;
    /**
     * 
     * @type {ProjectionPhenotype}
     * @memberof ConnectivityStatementUpdate
     */
    'projection_phenotype': ProjectionPhenotype;
    /**
     * 
     * @type {number}
     * @memberof ConnectivityStatementUpdate
     */
    'projection_phenotype_id'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ConnectivityStatementUpdate
     */
    'journey': string;
    /**
     * 
     * @type {ConnectivityStatementLaterality}
     * @memberof ConnectivityStatementUpdate
     */
    'laterality'?: ConnectivityStatementLaterality | null;
    /**
     * 
     * @type {ConnectivityStatementProjection}
     * @memberof ConnectivityStatementUpdate
     */
    'projection'?: ConnectivityStatementProjection | null;
    /**
     * 
     * @type {ConnectivityStatementCircuitType}
     * @memberof ConnectivityStatementUpdate
     */
    'circuit_type'?: ConnectivityStatementCircuitType | null;
    /**
     * 
     * @type {Array<Specie>}
     * @memberof ConnectivityStatementUpdate
     */
    'species'?: Array<Specie>;
    /**
     * 
     * @type {number}
     * @memberof ConnectivityStatementUpdate
     */
    'sex_id'?: number | null;
    /**
     * 
     * @type {Sex}
     * @memberof ConnectivityStatementUpdate
     */
    'sex': Sex;
    /**
     * 
     * @type {number}
     * @memberof ConnectivityStatementUpdate
     */
    'population_id'?: number | null;
    /**
     * 
     * @type {PopulationSet}
     * @memberof ConnectivityStatementUpdate
     */
    'population': PopulationSet;
    /**
     * 
     * @type {boolean}
     * @memberof ConnectivityStatementUpdate
     */
    'has_statement_been_exported': boolean;
    /**
     * 
     * @type {Array<number>}
     * @memberof ConnectivityStatementUpdate
     */
    'forward_connection'?: Array<number>;
    /**
     * 
     * @type {string}
     * @memberof ConnectivityStatementUpdate
     */
    'apinatomy_model'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ConnectivityStatementUpdate
     */
    'additional_information'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ConnectivityStatementUpdate
     */
    'modified_date': string;
    /**
     * 
     * @type {boolean}
     * @memberof ConnectivityStatementUpdate
     */
    'has_notes': boolean;
    /**
     * 
     * @type {string}
     * @memberof ConnectivityStatementUpdate
     */
    'statement_preview': string;
    /**
     * 
     * @type {Array<any>}
     * @memberof ConnectivityStatementUpdate
     */
    'errors': Array<any>;
    /**
     * 
     * @type {GraphState}
     * @memberof ConnectivityStatementUpdate
     */
    'graph_rendering_state'?: GraphState | null;
    /**
     * 
     * @type {Array<StatementAlert>}
     * @memberof ConnectivityStatementUpdate
     */
    'statement_alerts'?: Array<StatementAlert>;
}
/**
 * Destination
 * @export
 * @interface Destination
 */
export interface Destination {
    /**
     * 
     * @type {number}
     * @memberof Destination
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof Destination
     */
    'connectivity_statement': number;
    /**
     * 
     * @type {DestinationTypeEmum}
     * @memberof Destination
     */
    'type'?: DestinationTypeEmum;
    /**
     * 
     * @type {Array<number>}
     * @memberof Destination
     */
    'anatomical_entities'?: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof Destination
     */
    'from_entities'?: Array<number>;
}


/**
 * Destination with Custom Logic for from_entities
 * @export
 * @interface DestinationSerializerDetails
 */
export interface DestinationSerializerDetails {
    /**
     * 
     * @type {number}
     * @memberof DestinationSerializerDetails
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof DestinationSerializerDetails
     */
    'connectivity_statement_id': number;
    /**
     * 
     * @type {DestinationTypeEmum}
     * @memberof DestinationSerializerDetails
     */
    'type'?: DestinationTypeEmum;
    /**
     * 
     * @type {Array<AnatomicalEntity>}
     * @memberof DestinationSerializerDetails
     */
    'anatomical_entities': Array<AnatomicalEntity>;
    /**
     * 
     * @type {Array<AnatomicalEntity>}
     * @memberof DestinationSerializerDetails
     */
    'from_entities': Array<AnatomicalEntity>;
    /**
     * 
     * @type {string}
     * @memberof DestinationSerializerDetails
     */
    'are_connections_explicit': string;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const DestinationTypeEmum = {
    AxonT: 'AXON-T',
    AfferentT: 'AFFERENT-T',
    Unknown: 'UNKNOWN'
} as const;

export type DestinationTypeEmum = typeof DestinationTypeEmum[keyof typeof DestinationTypeEmum];


/**
 * 
 * @export
 * @interface GraphState
 */
export interface GraphState {
    /**
     * 
     * @type {{ [key: string]: any; }}
     * @memberof GraphState
     */
    'serialized_graph': { [key: string]: any; };
}
/**
 * Knowledge Statement
 * @export
 * @interface KnowledgeStatement
 */
export interface KnowledgeStatement {
    /**
     * 
     * @type {number}
     * @memberof KnowledgeStatement
     */
    'id': number | null;
    /**
     * 
     * @type {number}
     * @memberof KnowledgeStatement
     */
    'sentence_id'?: number;
    /**
     * 
     * @type {Array<Specie>}
     * @memberof KnowledgeStatement
     */
    'species'?: Array<Specie>;
    /**
     * 
     * @type {Array<AnatomicalEntity>}
     * @memberof KnowledgeStatement
     */
    'origins'?: Array<AnatomicalEntity>;
    /**
     * 
     * @type {Array<ViaSerializerDetails>}
     * @memberof KnowledgeStatement
     */
    'vias'?: Array<ViaSerializerDetails>;
    /**
     * 
     * @type {Array<DestinationSerializerDetails>}
     * @memberof KnowledgeStatement
     */
    'destinations'?: Array<DestinationSerializerDetails>;
    /**
     * 
     * @type {string}
     * @memberof KnowledgeStatement
     */
    'apinatomy_model'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof KnowledgeStatement
     */
    'phenotype_id'?: number | null;
    /**
     * 
     * @type {Phenotype}
     * @memberof KnowledgeStatement
     */
    'phenotype': Phenotype;
    /**
     * 
     * @type {string}
     * @memberof KnowledgeStatement
     */
    'reference_uri'?: string | null;
    /**
     * 
     * @type {Array<Provenance>}
     * @memberof KnowledgeStatement
     */
    'provenances'?: Array<Provenance>;
    /**
     * 
     * @type {string}
     * @memberof KnowledgeStatement
     */
    'knowledge_statement'?: string;
    /**
     * 
     * @type {string}
     * @memberof KnowledgeStatement
     */
    'journey': string;
    /**
     * 
     * @type {string}
     * @memberof KnowledgeStatement
     */
    'entities_journey': string;
    /**
     * 
     * @type {ConnectivityStatementLaterality}
     * @memberof KnowledgeStatement
     */
    'laterality'?: ConnectivityStatementLaterality | null;
    /**
     * 
     * @type {ConnectivityStatementProjection}
     * @memberof KnowledgeStatement
     */
    'projection'?: ConnectivityStatementProjection | null;
    /**
     * 
     * @type {ConnectivityStatementCircuitType}
     * @memberof KnowledgeStatement
     */
    'circuit_type'?: ConnectivityStatementCircuitType | null;
    /**
     * 
     * @type {Sex}
     * @memberof KnowledgeStatement
     */
    'sex': Sex;
    /**
     * 
     * @type {string}
     * @memberof KnowledgeStatement
     */
    'statement_preview': string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const LateralityEnum = {
    Right: 'RIGHT',
    Left: 'LEFT'
} as const;

export type LateralityEnum = typeof LateralityEnum[keyof typeof LateralityEnum];


/**
 * 
 * @export
 * @interface Login
 */
export interface Login {
    /**
     * 
     * @type {number}
     * @memberof Login
     */
    'status_code': number;
    /**
     * 
     * @type {string}
     * @memberof Login
     */
    'message'?: string;
    /**
     * 
     * @type {string}
     * @memberof Login
     */
    'redirect_url'?: string;
}
/**
 * 
 * @export
 * @interface Logout
 */
export interface Logout {
    /**
     * 
     * @type {number}
     * @memberof Logout
     */
    'status_code': number;
    /**
     * 
     * @type {string}
     * @memberof Logout
     */
    'message'?: string;
    /**
     * 
     * @type {string}
     * @memberof Logout
     */
    'redirect_url'?: string;
}
/**
 * Note
 * @export
 * @interface Note
 */
export interface Note {
    /**
     * 
     * @type {string}
     * @memberof Note
     */
    'note': string;
    /**
     * 
     * @type {string}
     * @memberof Note
     */
    'user': string | null;
    /**
     * 
     * @type {string}
     * @memberof Note
     */
    'created_at': string;
    /**
     * 
     * @type {number}
     * @memberof Note
     */
    'connectivity_statement_id'?: number;
    /**
     * 
     * @type {number}
     * @memberof Note
     */
    'sentence_id'?: number;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const NullEnum = {
} as const;

export type NullEnum = typeof NullEnum[keyof typeof NullEnum];


/**
 * 
 * @export
 * @interface PaginatedAlertTypeList
 */
export interface PaginatedAlertTypeList {
    /**
     * 
     * @type {number}
     * @memberof PaginatedAlertTypeList
     */
    'count'?: number;
    /**
     * 
     * @type {string}
     * @memberof PaginatedAlertTypeList
     */
    'next'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PaginatedAlertTypeList
     */
    'previous'?: string | null;
    /**
     * 
     * @type {Array<AlertType>}
     * @memberof PaginatedAlertTypeList
     */
    'results'?: Array<AlertType>;
}
/**
 * 
 * @export
 * @interface PaginatedAnatomicalEntityList
 */
export interface PaginatedAnatomicalEntityList {
    /**
     * 
     * @type {number}
     * @memberof PaginatedAnatomicalEntityList
     */
    'count'?: number;
    /**
     * 
     * @type {string}
     * @memberof PaginatedAnatomicalEntityList
     */
    'next'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PaginatedAnatomicalEntityList
     */
    'previous'?: string | null;
    /**
     * 
     * @type {Array<AnatomicalEntity>}
     * @memberof PaginatedAnatomicalEntityList
     */
    'results'?: Array<AnatomicalEntity>;
}
/**
 * 
 * @export
 * @interface PaginatedBaseConnectivityStatementList
 */
export interface PaginatedBaseConnectivityStatementList {
    /**
     * 
     * @type {number}
     * @memberof PaginatedBaseConnectivityStatementList
     */
    'count'?: number;
    /**
     * 
     * @type {string}
     * @memberof PaginatedBaseConnectivityStatementList
     */
    'next'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PaginatedBaseConnectivityStatementList
     */
    'previous'?: string | null;
    /**
     * 
     * @type {Array<BaseConnectivityStatement>}
     * @memberof PaginatedBaseConnectivityStatementList
     */
    'results'?: Array<BaseConnectivityStatement>;
}
/**
 * 
 * @export
 * @interface PaginatedDestinationList
 */
export interface PaginatedDestinationList {
    /**
     * 
     * @type {number}
     * @memberof PaginatedDestinationList
     */
    'count'?: number;
    /**
     * 
     * @type {string}
     * @memberof PaginatedDestinationList
     */
    'next'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PaginatedDestinationList
     */
    'previous'?: string | null;
    /**
     * 
     * @type {Array<Destination>}
     * @memberof PaginatedDestinationList
     */
    'results'?: Array<Destination>;
}
/**
 * 
 * @export
 * @interface PaginatedKnowledgeStatementList
 */
export interface PaginatedKnowledgeStatementList {
    /**
     * 
     * @type {number}
     * @memberof PaginatedKnowledgeStatementList
     */
    'count'?: number;
    /**
     * 
     * @type {string}
     * @memberof PaginatedKnowledgeStatementList
     */
    'next'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PaginatedKnowledgeStatementList
     */
    'previous'?: string | null;
    /**
     * 
     * @type {Array<KnowledgeStatement>}
     * @memberof PaginatedKnowledgeStatementList
     */
    'results'?: Array<KnowledgeStatement>;
}
/**
 * 
 * @export
 * @interface PaginatedNoteList
 */
export interface PaginatedNoteList {
    /**
     * 
     * @type {number}
     * @memberof PaginatedNoteList
     */
    'count'?: number;
    /**
     * 
     * @type {string}
     * @memberof PaginatedNoteList
     */
    'next'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PaginatedNoteList
     */
    'previous'?: string | null;
    /**
     * 
     * @type {Array<Note>}
     * @memberof PaginatedNoteList
     */
    'results'?: Array<Note>;
}
/**
 * 
 * @export
 * @interface PaginatedPhenotypeList
 */
export interface PaginatedPhenotypeList {
    /**
     * 
     * @type {number}
     * @memberof PaginatedPhenotypeList
     */
    'count'?: number;
    /**
     * 
     * @type {string}
     * @memberof PaginatedPhenotypeList
     */
    'next'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PaginatedPhenotypeList
     */
    'previous'?: string | null;
    /**
     * 
     * @type {Array<Phenotype>}
     * @memberof PaginatedPhenotypeList
     */
    'results'?: Array<Phenotype>;
}
/**
 * 
 * @export
 * @interface PaginatedPopulationSetList
 */
export interface PaginatedPopulationSetList {
    /**
     * 
     * @type {number}
     * @memberof PaginatedPopulationSetList
     */
    'count'?: number;
    /**
     * 
     * @type {string}
     * @memberof PaginatedPopulationSetList
     */
    'next'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PaginatedPopulationSetList
     */
    'previous'?: string | null;
    /**
     * 
     * @type {Array<PopulationSet>}
     * @memberof PaginatedPopulationSetList
     */
    'results'?: Array<PopulationSet>;
}
/**
 * 
 * @export
 * @interface PaginatedProjectionPhenotypeList
 */
export interface PaginatedProjectionPhenotypeList {
    /**
     * 
     * @type {number}
     * @memberof PaginatedProjectionPhenotypeList
     */
    'count'?: number;
    /**
     * 
     * @type {string}
     * @memberof PaginatedProjectionPhenotypeList
     */
    'next'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PaginatedProjectionPhenotypeList
     */
    'previous'?: string | null;
    /**
     * 
     * @type {Array<ProjectionPhenotype>}
     * @memberof PaginatedProjectionPhenotypeList
     */
    'results'?: Array<ProjectionPhenotype>;
}
/**
 * 
 * @export
 * @interface PaginatedSentenceList
 */
export interface PaginatedSentenceList {
    /**
     * 
     * @type {number}
     * @memberof PaginatedSentenceList
     */
    'count'?: number;
    /**
     * 
     * @type {string}
     * @memberof PaginatedSentenceList
     */
    'next'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PaginatedSentenceList
     */
    'previous'?: string | null;
    /**
     * 
     * @type {Array<Sentence>}
     * @memberof PaginatedSentenceList
     */
    'results'?: Array<Sentence>;
}
/**
 * 
 * @export
 * @interface PaginatedSexList
 */
export interface PaginatedSexList {
    /**
     * 
     * @type {number}
     * @memberof PaginatedSexList
     */
    'count'?: number;
    /**
     * 
     * @type {string}
     * @memberof PaginatedSexList
     */
    'next'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PaginatedSexList
     */
    'previous'?: string | null;
    /**
     * 
     * @type {Array<Sex>}
     * @memberof PaginatedSexList
     */
    'results'?: Array<Sex>;
}
/**
 * 
 * @export
 * @interface PaginatedSpecieList
 */
export interface PaginatedSpecieList {
    /**
     * 
     * @type {number}
     * @memberof PaginatedSpecieList
     */
    'count'?: number;
    /**
     * 
     * @type {string}
     * @memberof PaginatedSpecieList
     */
    'next'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PaginatedSpecieList
     */
    'previous'?: string | null;
    /**
     * 
     * @type {Array<Specie>}
     * @memberof PaginatedSpecieList
     */
    'results'?: Array<Specie>;
}
/**
 * 
 * @export
 * @interface PaginatedStatementAlertList
 */
export interface PaginatedStatementAlertList {
    /**
     * 
     * @type {number}
     * @memberof PaginatedStatementAlertList
     */
    'count'?: number;
    /**
     * 
     * @type {string}
     * @memberof PaginatedStatementAlertList
     */
    'next'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PaginatedStatementAlertList
     */
    'previous'?: string | null;
    /**
     * 
     * @type {Array<StatementAlert>}
     * @memberof PaginatedStatementAlertList
     */
    'results'?: Array<StatementAlert>;
}
/**
 * 
 * @export
 * @interface PaginatedTagList
 */
export interface PaginatedTagList {
    /**
     * 
     * @type {number}
     * @memberof PaginatedTagList
     */
    'count'?: number;
    /**
     * 
     * @type {string}
     * @memberof PaginatedTagList
     */
    'next'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PaginatedTagList
     */
    'previous'?: string | null;
    /**
     * 
     * @type {Array<Tag>}
     * @memberof PaginatedTagList
     */
    'results'?: Array<Tag>;
}
/**
 * 
 * @export
 * @interface PaginatedViaList
 */
export interface PaginatedViaList {
    /**
     * 
     * @type {number}
     * @memberof PaginatedViaList
     */
    'count'?: number;
    /**
     * 
     * @type {string}
     * @memberof PaginatedViaList
     */
    'next'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PaginatedViaList
     */
    'previous'?: string | null;
    /**
     * 
     * @type {Array<Via>}
     * @memberof PaginatedViaList
     */
    'results'?: Array<Via>;
}
/**
 * Connectivity Statement
 * @export
 * @interface PatchedConnectivityStatement
 */
export interface PatchedConnectivityStatement {
    /**
     * 
     * @type {number}
     * @memberof PatchedConnectivityStatement
     */
    'id'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof PatchedConnectivityStatement
     */
    'sentence_id'?: number;
    /**
     * 
     * @type {Sentence}
     * @memberof PatchedConnectivityStatement
     */
    'sentence'?: Sentence;
    /**
     * 
     * @type {string}
     * @memberof PatchedConnectivityStatement
     */
    'knowledge_statement'?: string;
    /**
     * 
     * @type {Array<Tag>}
     * @memberof PatchedConnectivityStatement
     */
    'tags'?: Array<Tag>;
    /**
     * 
     * @type {Array<Provenance>}
     * @memberof PatchedConnectivityStatement
     */
    'provenances'?: Array<Provenance>;
    /**
     * 
     * @type {User}
     * @memberof PatchedConnectivityStatement
     */
    'owner'?: User;
    /**
     * 
     * @type {number}
     * @memberof PatchedConnectivityStatement
     */
    'owner_id'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof PatchedConnectivityStatement
     */
    'state'?: string;
    /**
     * 
     * @type {Array<AvailableTransitionsBc5Enum>}
     * @memberof PatchedConnectivityStatement
     */
    'available_transitions'?: Array<AvailableTransitionsBc5Enum>;
    /**
     * 
     * @type {Array<AnatomicalEntity>}
     * @memberof PatchedConnectivityStatement
     */
    'origins'?: Array<AnatomicalEntity>;
    /**
     * 
     * @type {Array<ViaSerializerDetails>}
     * @memberof PatchedConnectivityStatement
     */
    'vias'?: Array<ViaSerializerDetails>;
    /**
     * 
     * @type {Array<DestinationSerializerDetails>}
     * @memberof PatchedConnectivityStatement
     */
    'destinations'?: Array<DestinationSerializerDetails>;
    /**
     * 
     * @type {number}
     * @memberof PatchedConnectivityStatement
     */
    'phenotype_id'?: number | null;
    /**
     * 
     * @type {Phenotype}
     * @memberof PatchedConnectivityStatement
     */
    'phenotype'?: Phenotype;
    /**
     * 
     * @type {ProjectionPhenotype}
     * @memberof PatchedConnectivityStatement
     */
    'projection_phenotype'?: ProjectionPhenotype;
    /**
     * 
     * @type {number}
     * @memberof PatchedConnectivityStatement
     */
    'projection_phenotype_id'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof PatchedConnectivityStatement
     */
    'journey'?: string;
    /**
     * 
     * @type {string}
     * @memberof PatchedConnectivityStatement
     */
    'entities_journey'?: string;
    /**
     * 
     * @type {ConnectivityStatementLaterality}
     * @memberof PatchedConnectivityStatement
     */
    'laterality'?: ConnectivityStatementLaterality | null;
    /**
     * 
     * @type {ConnectivityStatementProjection}
     * @memberof PatchedConnectivityStatement
     */
    'projection'?: ConnectivityStatementProjection | null;
    /**
     * 
     * @type {ConnectivityStatementCircuitType}
     * @memberof PatchedConnectivityStatement
     */
    'circuit_type'?: ConnectivityStatementCircuitType | null;
    /**
     * 
     * @type {Array<Specie>}
     * @memberof PatchedConnectivityStatement
     */
    'species'?: Array<Specie>;
    /**
     * 
     * @type {number}
     * @memberof PatchedConnectivityStatement
     */
    'sex_id'?: number | null;
    /**
     * 
     * @type {Sex}
     * @memberof PatchedConnectivityStatement
     */
    'sex'?: Sex;
    /**
     * 
     * @type {number}
     * @memberof PatchedConnectivityStatement
     */
    'population_id'?: number | null;
    /**
     * 
     * @type {PopulationSet}
     * @memberof PatchedConnectivityStatement
     */
    'population'?: PopulationSet;
    /**
     * 
     * @type {boolean}
     * @memberof PatchedConnectivityStatement
     */
    'has_statement_been_exported'?: boolean;
    /**
     * 
     * @type {Array<number>}
     * @memberof PatchedConnectivityStatement
     */
    'forward_connection'?: Array<number>;
    /**
     * 
     * @type {string}
     * @memberof PatchedConnectivityStatement
     */
    'apinatomy_model'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PatchedConnectivityStatement
     */
    'additional_information'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PatchedConnectivityStatement
     */
    'modified_date'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PatchedConnectivityStatement
     */
    'has_notes'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PatchedConnectivityStatement
     */
    'statement_preview'?: string;
    /**
     * 
     * @type {Array<any>}
     * @memberof PatchedConnectivityStatement
     */
    'errors'?: Array<any>;
    /**
     * 
     * @type {GraphState}
     * @memberof PatchedConnectivityStatement
     */
    'graph_rendering_state'?: GraphState | null;
    /**
     * 
     * @type {Array<StatementAlert>}
     * @memberof PatchedConnectivityStatement
     */
    'statement_alerts'?: Array<StatementAlert>;
}
/**
 * Connectivity Statement
 * @export
 * @interface PatchedConnectivityStatementUpdate
 */
export interface PatchedConnectivityStatementUpdate {
    /**
     * 
     * @type {number}
     * @memberof PatchedConnectivityStatementUpdate
     */
    'id'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof PatchedConnectivityStatementUpdate
     */
    'sentence_id'?: number;
    /**
     * 
     * @type {Sentence}
     * @memberof PatchedConnectivityStatementUpdate
     */
    'sentence'?: Sentence;
    /**
     * 
     * @type {string}
     * @memberof PatchedConnectivityStatementUpdate
     */
    'knowledge_statement'?: string;
    /**
     * 
     * @type {Array<Tag>}
     * @memberof PatchedConnectivityStatementUpdate
     */
    'tags'?: Array<Tag>;
    /**
     * 
     * @type {Array<Provenance>}
     * @memberof PatchedConnectivityStatementUpdate
     */
    'provenances'?: Array<Provenance>;
    /**
     * 
     * @type {User}
     * @memberof PatchedConnectivityStatementUpdate
     */
    'owner'?: User;
    /**
     * 
     * @type {number}
     * @memberof PatchedConnectivityStatementUpdate
     */
    'owner_id'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof PatchedConnectivityStatementUpdate
     */
    'state'?: string;
    /**
     * 
     * @type {Array<AvailableTransitionsBc5Enum>}
     * @memberof PatchedConnectivityStatementUpdate
     */
    'available_transitions'?: Array<AvailableTransitionsBc5Enum>;
    /**
     * 
     * @type {Array<number>}
     * @memberof PatchedConnectivityStatementUpdate
     */
    'origins'?: Array<number>;
    /**
     * 
     * @type {Array<ViaSerializerDetails>}
     * @memberof PatchedConnectivityStatementUpdate
     */
    'vias'?: Array<ViaSerializerDetails>;
    /**
     * 
     * @type {Array<DestinationSerializerDetails>}
     * @memberof PatchedConnectivityStatementUpdate
     */
    'destinations'?: Array<DestinationSerializerDetails>;
    /**
     * 
     * @type {number}
     * @memberof PatchedConnectivityStatementUpdate
     */
    'phenotype_id'?: number | null;
    /**
     * 
     * @type {Phenotype}
     * @memberof PatchedConnectivityStatementUpdate
     */
    'phenotype'?: Phenotype;
    /**
     * 
     * @type {ProjectionPhenotype}
     * @memberof PatchedConnectivityStatementUpdate
     */
    'projection_phenotype'?: ProjectionPhenotype;
    /**
     * 
     * @type {number}
     * @memberof PatchedConnectivityStatementUpdate
     */
    'projection_phenotype_id'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof PatchedConnectivityStatementUpdate
     */
    'journey'?: string;
    /**
     * 
     * @type {ConnectivityStatementLaterality}
     * @memberof PatchedConnectivityStatementUpdate
     */
    'laterality'?: ConnectivityStatementLaterality | null;
    /**
     * 
     * @type {ConnectivityStatementProjection}
     * @memberof PatchedConnectivityStatementUpdate
     */
    'projection'?: ConnectivityStatementProjection | null;
    /**
     * 
     * @type {ConnectivityStatementCircuitType}
     * @memberof PatchedConnectivityStatementUpdate
     */
    'circuit_type'?: ConnectivityStatementCircuitType | null;
    /**
     * 
     * @type {Array<Specie>}
     * @memberof PatchedConnectivityStatementUpdate
     */
    'species'?: Array<Specie>;
    /**
     * 
     * @type {number}
     * @memberof PatchedConnectivityStatementUpdate
     */
    'sex_id'?: number | null;
    /**
     * 
     * @type {Sex}
     * @memberof PatchedConnectivityStatementUpdate
     */
    'sex'?: Sex;
    /**
     * 
     * @type {number}
     * @memberof PatchedConnectivityStatementUpdate
     */
    'population_id'?: number | null;
    /**
     * 
     * @type {PopulationSet}
     * @memberof PatchedConnectivityStatementUpdate
     */
    'population'?: PopulationSet;
    /**
     * 
     * @type {boolean}
     * @memberof PatchedConnectivityStatementUpdate
     */
    'has_statement_been_exported'?: boolean;
    /**
     * 
     * @type {Array<number>}
     * @memberof PatchedConnectivityStatementUpdate
     */
    'forward_connection'?: Array<number>;
    /**
     * 
     * @type {string}
     * @memberof PatchedConnectivityStatementUpdate
     */
    'apinatomy_model'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PatchedConnectivityStatementUpdate
     */
    'additional_information'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PatchedConnectivityStatementUpdate
     */
    'modified_date'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PatchedConnectivityStatementUpdate
     */
    'has_notes'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PatchedConnectivityStatementUpdate
     */
    'statement_preview'?: string;
    /**
     * 
     * @type {Array<any>}
     * @memberof PatchedConnectivityStatementUpdate
     */
    'errors'?: Array<any>;
    /**
     * 
     * @type {GraphState}
     * @memberof PatchedConnectivityStatementUpdate
     */
    'graph_rendering_state'?: GraphState | null;
    /**
     * 
     * @type {Array<StatementAlert>}
     * @memberof PatchedConnectivityStatementUpdate
     */
    'statement_alerts'?: Array<StatementAlert>;
}
/**
 * Destination
 * @export
 * @interface PatchedDestination
 */
export interface PatchedDestination {
    /**
     * 
     * @type {number}
     * @memberof PatchedDestination
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof PatchedDestination
     */
    'connectivity_statement'?: number;
    /**
     * 
     * @type {DestinationTypeEmum}
     * @memberof PatchedDestination
     */
    'type'?: DestinationTypeEmum;
    /**
     * 
     * @type {Array<number>}
     * @memberof PatchedDestination
     */
    'anatomical_entities'?: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof PatchedDestination
     */
    'from_entities'?: Array<number>;
}


/**
 * Note
 * @export
 * @interface PatchedNote
 */
export interface PatchedNote {
    /**
     * 
     * @type {string}
     * @memberof PatchedNote
     */
    'note'?: string;
    /**
     * 
     * @type {string}
     * @memberof PatchedNote
     */
    'user'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PatchedNote
     */
    'created_at'?: string;
    /**
     * 
     * @type {number}
     * @memberof PatchedNote
     */
    'connectivity_statement_id'?: number;
    /**
     * 
     * @type {number}
     * @memberof PatchedNote
     */
    'sentence_id'?: number;
}
/**
 * Sentence
 * @export
 * @interface PatchedSentence
 */
export interface PatchedSentence {
    /**
     * 
     * @type {number}
     * @memberof PatchedSentence
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof PatchedSentence
     */
    'title'?: string;
    /**
     * 
     * @type {string}
     * @memberof PatchedSentence
     */
    'text'?: string;
    /**
     * 
     * @type {number}
     * @memberof PatchedSentence
     */
    'pmid'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof PatchedSentence
     */
    'pmcid'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PatchedSentence
     */
    'doi'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PatchedSentence
     */
    'batch_name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PatchedSentence
     */
    'external_ref'?: string | null;
    /**
     * 
     * @type {Array<Tag>}
     * @memberof PatchedSentence
     */
    'tags'?: Array<Tag>;
    /**
     * 
     * @type {User}
     * @memberof PatchedSentence
     */
    'owner'?: User;
    /**
     * 
     * @type {number}
     * @memberof PatchedSentence
     */
    'owner_id'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof PatchedSentence
     */
    'state'?: string;
    /**
     * 
     * @type {string}
     * @memberof PatchedSentence
     */
    'modified_date'?: string;
    /**
     * 
     * @type {Array<SentenceAvailableTransitionsEnum>}
     * @memberof PatchedSentence
     */
    'available_transitions'?: Array<SentenceAvailableTransitionsEnum>;
    /**
     * 
     * @type {Array<SentenceConnectivityStatement>}
     * @memberof PatchedSentence
     */
    'connectivity_statements'?: Array<SentenceConnectivityStatement>;
    /**
     * 
     * @type {boolean}
     * @memberof PatchedSentence
     */
    'has_notes'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PatchedSentence
     */
    'pmid_uri'?: string;
    /**
     * 
     * @type {string}
     * @memberof PatchedSentence
     */
    'pmcid_uri'?: string;
    /**
     * 
     * @type {string}
     * @memberof PatchedSentence
     */
    'doi_uri'?: string;
}
/**
 * 
 * @export
 * @interface PatchedStatementAlert
 */
export interface PatchedStatementAlert {
    /**
     * 
     * @type {number}
     * @memberof PatchedStatementAlert
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof PatchedStatementAlert
     */
    'alert_type'?: number;
    /**
     * 
     * @type {string}
     * @memberof PatchedStatementAlert
     */
    'text'?: string;
    /**
     * 
     * @type {number}
     * @memberof PatchedStatementAlert
     */
    'saved_by'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof PatchedStatementAlert
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof PatchedStatementAlert
     */
    'updated_at'?: string;
    /**
     * 
     * @type {number}
     * @memberof PatchedStatementAlert
     */
    'connectivity_statement_id'?: number;
}
/**
 * Via
 * @export
 * @interface PatchedVia
 */
export interface PatchedVia {
    /**
     * 
     * @type {number}
     * @memberof PatchedVia
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof PatchedVia
     */
    'order'?: number;
    /**
     * 
     * @type {number}
     * @memberof PatchedVia
     */
    'connectivity_statement'?: number;
    /**
     * 
     * @type {ViaTypeEnum}
     * @memberof PatchedVia
     */
    'type'?: ViaTypeEnum;
    /**
     * 
     * @type {Array<number>}
     * @memberof PatchedVia
     */
    'anatomical_entities'?: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof PatchedVia
     */
    'from_entities'?: Array<number>;
}


/**
 * Phenotype
 * @export
 * @interface Phenotype
 */
export interface Phenotype {
    /**
     * 
     * @type {number}
     * @memberof Phenotype
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof Phenotype
     */
    'name': string;
}
/**
 * Population Set
 * @export
 * @interface PopulationSet
 */
export interface PopulationSet {
    /**
     * 
     * @type {number}
     * @memberof PopulationSet
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof PopulationSet
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof PopulationSet
     */
    'description'?: string | null;
}
/**
 * Profile
 * @export
 * @interface Profile
 */
export interface Profile {
    /**
     * 
     * @type {number}
     * @memberof Profile
     */
    'id': number;
    /**
     * 
     * @type {User}
     * @memberof Profile
     */
    'user': User;
    /**
     * 
     * @type {boolean}
     * @memberof Profile
     */
    'is_triage_operator'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Profile
     */
    'is_curator'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Profile
     */
    'is_reviewer'?: boolean;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const ProjectionEnum = {
    Ipsi: 'IPSI',
    Contrat: 'CONTRAT',
    Bi: 'BI'
} as const;

export type ProjectionEnum = typeof ProjectionEnum[keyof typeof ProjectionEnum];


/**
 * Phenotype
 * @export
 * @interface ProjectionPhenotype
 */
export interface ProjectionPhenotype {
    /**
     * 
     * @type {number}
     * @memberof ProjectionPhenotype
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof ProjectionPhenotype
     */
    'name': string;
}
/**
 * Provenance
 * @export
 * @interface Provenance
 */
export interface Provenance {
    /**
     * 
     * @type {number}
     * @memberof Provenance
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof Provenance
     */
    'uri': string;
    /**
     * 
     * @type {number}
     * @memberof Provenance
     */
    'connectivity_statement_id': number;
}
/**
 * Sentence
 * @export
 * @interface Sentence
 */
export interface Sentence {
    /**
     * 
     * @type {number}
     * @memberof Sentence
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof Sentence
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof Sentence
     */
    'text': string;
    /**
     * 
     * @type {number}
     * @memberof Sentence
     */
    'pmid'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof Sentence
     */
    'pmcid'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Sentence
     */
    'doi'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Sentence
     */
    'batch_name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Sentence
     */
    'external_ref'?: string | null;
    /**
     * 
     * @type {Array<Tag>}
     * @memberof Sentence
     */
    'tags': Array<Tag>;
    /**
     * 
     * @type {User}
     * @memberof Sentence
     */
    'owner': User;
    /**
     * 
     * @type {number}
     * @memberof Sentence
     */
    'owner_id'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof Sentence
     */
    'state': string;
    /**
     * 
     * @type {string}
     * @memberof Sentence
     */
    'modified_date': string;
    /**
     * 
     * @type {Array<SentenceAvailableTransitionsEnum>}
     * @memberof Sentence
     */
    'available_transitions': Array<SentenceAvailableTransitionsEnum>;
    /**
     * 
     * @type {Array<SentenceConnectivityStatement>}
     * @memberof Sentence
     */
    'connectivity_statements': Array<SentenceConnectivityStatement>;
    /**
     * 
     * @type {boolean}
     * @memberof Sentence
     */
    'has_notes': boolean;
    /**
     * 
     * @type {string}
     * @memberof Sentence
     */
    'pmid_uri': string;
    /**
     * 
     * @type {string}
     * @memberof Sentence
     */
    'pmcid_uri': string;
    /**
     * 
     * @type {string}
     * @memberof Sentence
     */
    'doi_uri': string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const SentenceAvailableTransitionsEnum = {
    Open: 'open',
    NeedsFurtherReview: 'needs_further_review',
    ComposeLater: 'compose_later',
    ReadyToCompose: 'ready_to_compose',
    ComposeNow: 'compose_now',
    Completed: 'completed',
    Excluded: 'excluded'
} as const;

export type SentenceAvailableTransitionsEnum = typeof SentenceAvailableTransitionsEnum[keyof typeof SentenceAvailableTransitionsEnum];


/**
 * Connectivity Statement
 * @export
 * @interface SentenceConnectivityStatement
 */
export interface SentenceConnectivityStatement {
    /**
     * 
     * @type {number}
     * @memberof SentenceConnectivityStatement
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof SentenceConnectivityStatement
     */
    'sentence_id': number;
    /**
     * 
     * @type {string}
     * @memberof SentenceConnectivityStatement
     */
    'knowledge_statement': string;
    /**
     * 
     * @type {Array<Provenance>}
     * @memberof SentenceConnectivityStatement
     */
    'provenances': Array<Provenance>;
    /**
     * 
     * @type {number}
     * @memberof SentenceConnectivityStatement
     */
    'phenotype_id'?: number | null;
    /**
     * 
     * @type {Phenotype}
     * @memberof SentenceConnectivityStatement
     */
    'phenotype': Phenotype;
    /**
     * 
     * @type {ProjectionPhenotype}
     * @memberof SentenceConnectivityStatement
     */
    'projection_phenotype': ProjectionPhenotype;
    /**
     * 
     * @type {number}
     * @memberof SentenceConnectivityStatement
     */
    'projection_phenotype_id'?: number | null;
    /**
     * 
     * @type {LateralityEnum}
     * @memberof SentenceConnectivityStatement
     */
    'laterality': LateralityEnum | null;
    /**
     * 
     * @type {ProjectionEnum}
     * @memberof SentenceConnectivityStatement
     */
    'projection': ProjectionEnum | null;
    /**
     * 
     * @type {CircuitTypeEnum}
     * @memberof SentenceConnectivityStatement
     */
    'circuit_type': CircuitTypeEnum | null;
    /**
     * 
     * @type {Array<Specie>}
     * @memberof SentenceConnectivityStatement
     */
    'species': Array<Specie>;
    /**
     * 
     * @type {number}
     * @memberof SentenceConnectivityStatement
     */
    'sex_id'?: number | null;
    /**
     * 
     * @type {Sex}
     * @memberof SentenceConnectivityStatement
     */
    'sex': Sex;
    /**
     * 
     * @type {number}
     * @memberof SentenceConnectivityStatement
     */
    'population_id'?: number | null;
    /**
     * 
     * @type {PopulationSet}
     * @memberof SentenceConnectivityStatement
     */
    'population': PopulationSet;
    /**
     * 
     * @type {boolean}
     * @memberof SentenceConnectivityStatement
     */
    'has_statement_been_exported': boolean;
    /**
     * 
     * @type {string}
     * @memberof SentenceConnectivityStatement
     */
    'apinatomy_model': string | null;
    /**
     * 
     * @type {string}
     * @memberof SentenceConnectivityStatement
     */
    'additional_information': string | null;
    /**
     * 
     * @type {number}
     * @memberof SentenceConnectivityStatement
     */
    'owner_id'?: number | null;
    /**
     * 
     * @type {User}
     * @memberof SentenceConnectivityStatement
     */
    'owner': User;
}


/**
 * Sex
 * @export
 * @interface Sex
 */
export interface Sex {
    /**
     * 
     * @type {number}
     * @memberof Sex
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof Sex
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof Sex
     */
    'ontology_uri': string;
}
/**
 * Specie
 * @export
 * @interface Specie
 */
export interface Specie {
    /**
     * 
     * @type {number}
     * @memberof Specie
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof Specie
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof Specie
     */
    'ontology_uri'?: string | null;
}
/**
 * 
 * @export
 * @interface StatementAlert
 */
export interface StatementAlert {
    /**
     * 
     * @type {number}
     * @memberof StatementAlert
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof StatementAlert
     */
    'alert_type': number;
    /**
     * 
     * @type {string}
     * @memberof StatementAlert
     */
    'text'?: string;
    /**
     * 
     * @type {number}
     * @memberof StatementAlert
     */
    'saved_by': number | null;
    /**
     * 
     * @type {string}
     * @memberof StatementAlert
     */
    'created_at': string;
    /**
     * 
     * @type {string}
     * @memberof StatementAlert
     */
    'updated_at': string;
    /**
     * 
     * @type {number}
     * @memberof StatementAlert
     */
    'connectivity_statement_id': number;
}
/**
 * Note Tag
 * @export
 * @interface Tag
 */
export interface Tag {
    /**
     * 
     * @type {number}
     * @memberof Tag
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof Tag
     */
    'tag': string;
}
/**
 * User
 * @export
 * @interface User
 */
export interface User {
    /**
     * 
     * @type {number}
     * @memberof User
     */
    'id': number;
    /**
     * Required. 150 characters or fewer. Letters, digits and @/./+/-/_ only.
     * @type {string}
     * @memberof User
     */
    'username': string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'first_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'last_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'email'?: string;
    /**
     * Designates whether the user can log into this admin site.
     * @type {boolean}
     * @memberof User
     */
    'is_staff'?: boolean;
}
/**
 * Via
 * @export
 * @interface Via
 */
export interface Via {
    /**
     * 
     * @type {number}
     * @memberof Via
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof Via
     */
    'order': number;
    /**
     * 
     * @type {number}
     * @memberof Via
     */
    'connectivity_statement'?: number;
    /**
     * 
     * @type {ViaTypeEnum}
     * @memberof Via
     */
    'type'?: ViaTypeEnum;
    /**
     * 
     * @type {Array<number>}
     * @memberof Via
     */
    'anatomical_entities'?: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof Via
     */
    'from_entities'?: Array<number>;
}


/**
 * Via Serializer with Custom Logic for from_entities
 * @export
 * @interface ViaSerializerDetails
 */
export interface ViaSerializerDetails {
    /**
     * 
     * @type {number}
     * @memberof ViaSerializerDetails
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof ViaSerializerDetails
     */
    'order': number;
    /**
     * 
     * @type {number}
     * @memberof ViaSerializerDetails
     */
    'connectivity_statement_id': number;
    /**
     * 
     * @type {ViaTypeEnum}
     * @memberof ViaSerializerDetails
     */
    'type'?: ViaTypeEnum;
    /**
     * 
     * @type {Array<AnatomicalEntity>}
     * @memberof ViaSerializerDetails
     */
    'anatomical_entities': Array<AnatomicalEntity>;
    /**
     * 
     * @type {Array<AnatomicalEntity>}
     * @memberof ViaSerializerDetails
     */
    'from_entities': Array<AnatomicalEntity>;
    /**
     * 
     * @type {string}
     * @memberof ViaSerializerDetails
     */
    'are_connections_explicit': string;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const ViaTypeEnum = {
    Axon: 'AXON',
    Dendrite: 'DENDRITE',
    SensoryAxon: 'SENSORY_AXON'
} as const;

export type ViaTypeEnum = typeof ViaTypeEnum[keyof typeof ViaTypeEnum];


/**
 * 
 * @export
 * @interface WriteNote
 */
export interface WriteNote {
    /**
     * The bulk action to perform.
     * @type {ActionEnum}
     * @memberof WriteNote
     */
    'action': ActionEnum;
    /**
     * The note text.
     * @type {string}
     * @memberof WriteNote
     */
    'note_text': string;
}



/**
 * ComposerApi - axios parameter creator
 * @export
 */
export const ComposerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * A viewset for viewing the list of alert types.
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        composerAlertList: async (limit?: number, offset?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/composer/alert/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication tokenAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication cookieAuth required

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * A viewset for viewing the list of alert types.
         * @param {number} id A unique integer value identifying this alert type.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        composerAlertRetrieve: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('composerAlertRetrieve', 'id', id)
            const localVarPath = `/api/composer/alert/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication tokenAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * AnatomicalEntity
         * @param {Array<number>} [excludeIds] Multiple values may be separated by commas.
         * @param {number} [limit] Number of results to return per page.
         * @param {string} [name] 
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        composerAnatomicalEntityList: async (excludeIds?: Array<number>, limit?: number, name?: string, offset?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/composer/anatomical-entity/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication tokenAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication cookieAuth required

            if (excludeIds) {
                localVarQueryParameter['exclude_ids'] = excludeIds.join(COLLECTION_FORMATS.csv);
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * AnatomicalEntity
         * @param {number} id A unique integer value identifying this Anatomical Entity.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        composerAnatomicalEntityRetrieve: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('composerAnatomicalEntityRetrieve', 'id', id)
            const localVarPath = `/api/composer/anatomical-entity/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication tokenAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ConnectivityStatement
         * @param {number} id A unique integer value identifying this connectivity statement.
         * @param {string} uri 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        composerConnectivityStatementAddProvenanceCreate: async (id: number, uri: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('composerConnectivityStatementAddProvenanceCreate', 'id', id)
            // verify required parameter 'uri' is not null or undefined
            assertParamExists('composerConnectivityStatementAddProvenanceCreate', 'uri', uri)
            const localVarPath = `/api/composer/connectivity-statement/{id}/add_provenance/{uri}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"uri"}}`, encodeURIComponent(String(uri)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication tokenAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ConnectivityStatement
         * @param {number} id A unique integer value identifying this connectivity statement.
         * @param {number} specieId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        composerConnectivityStatementAddSpecieCreate: async (id: number, specieId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('composerConnectivityStatementAddSpecieCreate', 'id', id)
            // verify required parameter 'specieId' is not null or undefined
            assertParamExists('composerConnectivityStatementAddSpecieCreate', 'specieId', specieId)
            const localVarPath = `/api/composer/connectivity-statement/{id}/add_specie/{specie_id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"specie_id"}}`, encodeURIComponent(String(specieId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication tokenAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ConnectivityStatement
         * @param {number} id A unique integer value identifying this connectivity statement.
         * @param {number} tagId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        composerConnectivityStatementAddTagCreate: async (id: number, tagId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('composerConnectivityStatementAddTagCreate', 'id', id)
            // verify required parameter 'tagId' is not null or undefined
            assertParamExists('composerConnectivityStatementAddTagCreate', 'tagId', tagId)
            const localVarPath = `/api/composer/connectivity-statement/{id}/add_tag/{tag_id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"tag_id"}}`, encodeURIComponent(String(tagId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication tokenAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ConnectivityStatement
         * @param {number} id A unique integer value identifying this connectivity statement.
         * @param {PatchedConnectivityStatement} [patchedConnectivityStatement] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        composerConnectivityStatementAssignOwnerPartialUpdate: async (id: number, patchedConnectivityStatement?: PatchedConnectivityStatement, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('composerConnectivityStatementAssignOwnerPartialUpdate', 'id', id)
            const localVarPath = `/api/composer/connectivity-statement/{id}/assign_owner/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication tokenAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication cookieAuth required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(patchedConnectivityStatement, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns available users for assignment and possible state transitions for the selected items.
         * @param {Array<number>} [destinations] 
         * @param {Array<number>} [excludeIds] Multiple values may be separated by commas.
         * @param {number} [excludeSentenceId] 
         * @param {boolean} [hasStatementBeenExported] Is Exported
         * @param {Array<number>} [include] Multiple values may be separated by commas.
         * @param {string} [knowledgeStatement] 
         * @param {boolean} [notes] Checks if entity has notes
         * @param {Array<ComposerConnectivityStatementAvailableOptionsRetrieveOrderingEnum>} [ordering] Ordering
         * @param {Array<number>} [origins] 
         * @param {Array<number>} [populationset] 
         * @param {number} [sentenceId] 
         * @param {Array<ComposerConnectivityStatementAvailableOptionsRetrieveStateEnum>} [state] 
         * @param {Array<number>} [tags] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        composerConnectivityStatementAvailableOptionsRetrieve: async (destinations?: Array<number>, excludeIds?: Array<number>, excludeSentenceId?: number, hasStatementBeenExported?: boolean, include?: Array<number>, knowledgeStatement?: string, notes?: boolean, ordering?: Array<ComposerConnectivityStatementAvailableOptionsRetrieveOrderingEnum>, origins?: Array<number>, populationset?: Array<number>, sentenceId?: number, state?: Array<ComposerConnectivityStatementAvailableOptionsRetrieveStateEnum>, tags?: Array<number>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/composer/connectivity-statement/available_options/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication tokenAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication cookieAuth required

            if (destinations) {
                localVarQueryParameter['destinations'] = destinations;
            }

            if (excludeIds) {
                localVarQueryParameter['exclude_ids'] = excludeIds.join(COLLECTION_FORMATS.csv);
            }

            if (excludeSentenceId !== undefined) {
                localVarQueryParameter['exclude_sentence_id'] = excludeSentenceId;
            }

            if (hasStatementBeenExported !== undefined) {
                localVarQueryParameter['has_statement_been_exported'] = hasStatementBeenExported;
            }

            if (include) {
                localVarQueryParameter['include'] = include.join(COLLECTION_FORMATS.csv);
            }

            if (knowledgeStatement !== undefined) {
                localVarQueryParameter['knowledge_statement'] = knowledgeStatement;
            }

            if (notes !== undefined) {
                localVarQueryParameter['notes'] = notes;
            }

            if (ordering) {
                localVarQueryParameter['ordering'] = ordering.join(COLLECTION_FORMATS.csv);
            }

            if (origins) {
                localVarQueryParameter['origins'] = origins;
            }

            if (populationset) {
                localVarQueryParameter['populationset'] = populationset;
            }

            if (sentenceId !== undefined) {
                localVarQueryParameter['sentence_id'] = sentenceId;
            }

            if (state) {
                localVarQueryParameter['state'] = state;
            }

            if (tags) {
                localVarQueryParameter['tags'] = tags;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Apply a bulk action to the selected items and return the number of items updated successfully.
         * @param {Array<number>} [destinations] 
         * @param {Array<number>} [excludeIds] Multiple values may be separated by commas.
         * @param {number} [excludeSentenceId] 
         * @param {boolean} [hasStatementBeenExported] Is Exported
         * @param {Array<number>} [include] Multiple values may be separated by commas.
         * @param {string} [knowledgeStatement] 
         * @param {boolean} [notes] Checks if entity has notes
         * @param {Array<ComposerConnectivityStatementBulkActionCreateOrderingEnum>} [ordering] Ordering
         * @param {Array<number>} [origins] 
         * @param {Array<number>} [populationset] 
         * @param {number} [sentenceId] 
         * @param {Array<ComposerConnectivityStatementBulkActionCreateStateEnum>} [state] 
         * @param {Array<number>} [tags] 
         * @param {BulkAction} [bulkAction] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        composerConnectivityStatementBulkActionCreate: async (destinations?: Array<number>, excludeIds?: Array<number>, excludeSentenceId?: number, hasStatementBeenExported?: boolean, include?: Array<number>, knowledgeStatement?: string, notes?: boolean, ordering?: Array<ComposerConnectivityStatementBulkActionCreateOrderingEnum>, origins?: Array<number>, populationset?: Array<number>, sentenceId?: number, state?: Array<ComposerConnectivityStatementBulkActionCreateStateEnum>, tags?: Array<number>, bulkAction?: BulkAction, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/composer/connectivity-statement/bulk_action/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication tokenAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication cookieAuth required

            if (destinations) {
                localVarQueryParameter['destinations'] = destinations;
            }

            if (excludeIds) {
                localVarQueryParameter['exclude_ids'] = excludeIds.join(COLLECTION_FORMATS.csv);
            }

            if (excludeSentenceId !== undefined) {
                localVarQueryParameter['exclude_sentence_id'] = excludeSentenceId;
            }

            if (hasStatementBeenExported !== undefined) {
                localVarQueryParameter['has_statement_been_exported'] = hasStatementBeenExported;
            }

            if (include) {
                localVarQueryParameter['include'] = include.join(COLLECTION_FORMATS.csv);
            }

            if (knowledgeStatement !== undefined) {
                localVarQueryParameter['knowledge_statement'] = knowledgeStatement;
            }

            if (notes !== undefined) {
                localVarQueryParameter['notes'] = notes;
            }

            if (ordering) {
                localVarQueryParameter['ordering'] = ordering.join(COLLECTION_FORMATS.csv);
            }

            if (origins) {
                localVarQueryParameter['origins'] = origins;
            }

            if (populationset) {
                localVarQueryParameter['populationset'] = populationset;
            }

            if (sentenceId !== undefined) {
                localVarQueryParameter['sentence_id'] = sentenceId;
            }

            if (state) {
                localVarQueryParameter['state'] = state;
            }

            if (tags) {
                localVarQueryParameter['tags'] = tags;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(bulkAction, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ConnectivityStatement
         * @param {number} id A unique integer value identifying this connectivity statement.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        composerConnectivityStatementCloneStatementRetrieve: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('composerConnectivityStatementCloneStatementRetrieve', 'id', id)
            const localVarPath = `/api/composer/connectivity-statement/{id}/clone_statement/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication tokenAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ConnectivityStatement
         * @param {ConnectivityStatement} [connectivityStatement] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        composerConnectivityStatementCreate: async (connectivityStatement?: ConnectivityStatement, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/composer/connectivity-statement/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication tokenAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication cookieAuth required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(connectivityStatement, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ConnectivityStatement
         * @param {number} id A unique integer value identifying this connectivity statement.
         * @param {number} provenanceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        composerConnectivityStatementDelProvenanceDestroy: async (id: number, provenanceId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('composerConnectivityStatementDelProvenanceDestroy', 'id', id)
            // verify required parameter 'provenanceId' is not null or undefined
            assertParamExists('composerConnectivityStatementDelProvenanceDestroy', 'provenanceId', provenanceId)
            const localVarPath = `/api/composer/connectivity-statement/{id}/del_provenance/{provenance_id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"provenance_id"}}`, encodeURIComponent(String(provenanceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication tokenAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ConnectivityStatement
         * @param {number} id A unique integer value identifying this connectivity statement.
         * @param {number} specieId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        composerConnectivityStatementDelSpecieCreate: async (id: number, specieId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('composerConnectivityStatementDelSpecieCreate', 'id', id)
            // verify required parameter 'specieId' is not null or undefined
            assertParamExists('composerConnectivityStatementDelSpecieCreate', 'specieId', specieId)
            const localVarPath = `/api/composer/connectivity-statement/{id}/del_specie/{specie_id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"specie_id"}}`, encodeURIComponent(String(specieId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication tokenAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ConnectivityStatement
         * @param {number} id A unique integer value identifying this connectivity statement.
         * @param {number} tagId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        composerConnectivityStatementDelTagCreate: async (id: number, tagId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('composerConnectivityStatementDelTagCreate', 'id', id)
            // verify required parameter 'tagId' is not null or undefined
            assertParamExists('composerConnectivityStatementDelTagCreate', 'tagId', tagId)
            const localVarPath = `/api/composer/connectivity-statement/{id}/del_tag/{tag_id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"tag_id"}}`, encodeURIComponent(String(tagId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication tokenAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ConnectivityStatement
         * @param {number} id A unique integer value identifying this connectivity statement.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        composerConnectivityStatementDestroy: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('composerConnectivityStatementDestroy', 'id', id)
            const localVarPath = `/api/composer/connectivity-statement/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication tokenAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ConnectivityStatement
         * @param {number} id A unique integer value identifying this connectivity statement.
         * @param {string} transition 
         * @param {ConnectivityStatement} [connectivityStatement] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        composerConnectivityStatementDoTransitionCreate: async (id: number, transition: string, connectivityStatement?: ConnectivityStatement, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('composerConnectivityStatementDoTransitionCreate', 'id', id)
            // verify required parameter 'transition' is not null or undefined
            assertParamExists('composerConnectivityStatementDoTransitionCreate', 'transition', transition)
            const localVarPath = `/api/composer/connectivity-statement/{id}/do_transition/{transition}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"transition"}}`, encodeURIComponent(String(transition)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication tokenAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication cookieAuth required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(connectivityStatement, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ConnectivityStatement
         * @param {Array<number>} [destinations] 
         * @param {Array<number>} [excludeIds] Multiple values may be separated by commas.
         * @param {number} [excludeSentenceId] 
         * @param {boolean} [hasStatementBeenExported] Is Exported
         * @param {Array<number>} [include] Multiple values may be separated by commas.
         * @param {string} [knowledgeStatement] 
         * @param {number} [limit] Number of results to return per page.
         * @param {boolean} [notes] Checks if entity has notes
         * @param {number} [offset] The initial index from which to return the results.
         * @param {Array<ComposerConnectivityStatementListOrderingEnum>} [ordering] Ordering
         * @param {Array<number>} [origins] 
         * @param {Array<number>} [populationset] 
         * @param {number} [sentenceId] 
         * @param {Array<ComposerConnectivityStatementListStateEnum>} [state] 
         * @param {Array<number>} [tags] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        composerConnectivityStatementList: async (destinations?: Array<number>, excludeIds?: Array<number>, excludeSentenceId?: number, hasStatementBeenExported?: boolean, include?: Array<number>, knowledgeStatement?: string, limit?: number, notes?: boolean, offset?: number, ordering?: Array<ComposerConnectivityStatementListOrderingEnum>, origins?: Array<number>, populationset?: Array<number>, sentenceId?: number, state?: Array<ComposerConnectivityStatementListStateEnum>, tags?: Array<number>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/composer/connectivity-statement/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication tokenAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication cookieAuth required

            if (destinations) {
                localVarQueryParameter['destinations'] = destinations;
            }

            if (excludeIds) {
                localVarQueryParameter['exclude_ids'] = excludeIds.join(COLLECTION_FORMATS.csv);
            }

            if (excludeSentenceId !== undefined) {
                localVarQueryParameter['exclude_sentence_id'] = excludeSentenceId;
            }

            if (hasStatementBeenExported !== undefined) {
                localVarQueryParameter['has_statement_been_exported'] = hasStatementBeenExported;
            }

            if (include) {
                localVarQueryParameter['include'] = include.join(COLLECTION_FORMATS.csv);
            }

            if (knowledgeStatement !== undefined) {
                localVarQueryParameter['knowledge_statement'] = knowledgeStatement;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (notes !== undefined) {
                localVarQueryParameter['notes'] = notes;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (ordering) {
                localVarQueryParameter['ordering'] = ordering.join(COLLECTION_FORMATS.csv);
            }

            if (origins) {
                localVarQueryParameter['origins'] = origins;
            }

            if (populationset) {
                localVarQueryParameter['populationset'] = populationset;
            }

            if (sentenceId !== undefined) {
                localVarQueryParameter['sentence_id'] = sentenceId;
            }

            if (state) {
                localVarQueryParameter['state'] = state;
            }

            if (tags) {
                localVarQueryParameter['tags'] = tags;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ConnectivityStatement
         * @param {number} id A unique integer value identifying this connectivity statement.
         * @param {PatchedConnectivityStatementUpdate} [patchedConnectivityStatementUpdate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        composerConnectivityStatementPartialUpdate: async (id: number, patchedConnectivityStatementUpdate?: PatchedConnectivityStatementUpdate, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('composerConnectivityStatementPartialUpdate', 'id', id)
            const localVarPath = `/api/composer/connectivity-statement/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication tokenAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication cookieAuth required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(patchedConnectivityStatementUpdate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ConnectivityStatement
         * @param {number} id A unique integer value identifying this connectivity statement.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        composerConnectivityStatementRetrieve: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('composerConnectivityStatementRetrieve', 'id', id)
            const localVarPath = `/api/composer/connectivity-statement/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication tokenAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ConnectivityStatement
         * @param {number} id A unique integer value identifying this connectivity statement.
         * @param {ConnectivityStatementUpdate} connectivityStatementUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        composerConnectivityStatementUpdate: async (id: number, connectivityStatementUpdate: ConnectivityStatementUpdate, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('composerConnectivityStatementUpdate', 'id', id)
            // verify required parameter 'connectivityStatementUpdate' is not null or undefined
            assertParamExists('composerConnectivityStatementUpdate', 'connectivityStatementUpdate', connectivityStatementUpdate)
            const localVarPath = `/api/composer/connectivity-statement/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication tokenAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication cookieAuth required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(connectivityStatementUpdate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Destination
         * @param {Destination} destination 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        composerDestinationCreate: async (destination: Destination, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'destination' is not null or undefined
            assertParamExists('composerDestinationCreate', 'destination', destination)
            const localVarPath = `/api/composer/destination/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication tokenAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication cookieAuth required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(destination, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Destination
         * @param {number} id A unique integer value identifying this destination.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        composerDestinationDestroy: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('composerDestinationDestroy', 'id', id)
            const localVarPath = `/api/composer/destination/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication tokenAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Destination
         * @param {number} [connectivityStatementId] 
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        composerDestinationList: async (connectivityStatementId?: number, limit?: number, offset?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/composer/destination/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication tokenAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication cookieAuth required

            if (connectivityStatementId !== undefined) {
                localVarQueryParameter['connectivity_statement_id'] = connectivityStatementId;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Destination
         * @param {number} id A unique integer value identifying this destination.
         * @param {PatchedDestination} [patchedDestination] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        composerDestinationPartialUpdate: async (id: number, patchedDestination?: PatchedDestination, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('composerDestinationPartialUpdate', 'id', id)
            const localVarPath = `/api/composer/destination/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication tokenAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication cookieAuth required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(patchedDestination, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Destination
         * @param {number} id A unique integer value identifying this destination.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        composerDestinationRetrieve: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('composerDestinationRetrieve', 'id', id)
            const localVarPath = `/api/composer/destination/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication tokenAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Destination
         * @param {number} id A unique integer value identifying this destination.
         * @param {Destination} destination 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        composerDestinationUpdate: async (id: number, destination: Destination, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('composerDestinationUpdate', 'id', id)
            // verify required parameter 'destination' is not null or undefined
            assertParamExists('composerDestinationUpdate', 'destination', destination)
            const localVarPath = `/api/composer/destination/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication tokenAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication cookieAuth required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(destination, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        composerJsonschemasRetrieve: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/composer/jsonschemas/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication tokenAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Note
         * @param {Note} note 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        composerNoteCreate: async (note: Note, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'note' is not null or undefined
            assertParamExists('composerNoteCreate', 'note', note)
            const localVarPath = `/api/composer/note/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication tokenAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication cookieAuth required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(note, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Note
         * @param {number} id A unique integer value identifying this note.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        composerNoteDestroy: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('composerNoteDestroy', 'id', id)
            const localVarPath = `/api/composer/note/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication tokenAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Note
         * @param {number | null} [connectivityStatementId] 
         * @param {boolean} [includeSystemNotes] Include System Notes
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {number | null} [sentenceId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        composerNoteList: async (connectivityStatementId?: number | null, includeSystemNotes?: boolean, limit?: number, offset?: number, sentenceId?: number | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/composer/note/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication tokenAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication cookieAuth required

            if (connectivityStatementId !== undefined) {
                localVarQueryParameter['connectivity_statement_id'] = connectivityStatementId;
            }

            if (includeSystemNotes !== undefined) {
                localVarQueryParameter['include_system_notes'] = includeSystemNotes;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (sentenceId !== undefined) {
                localVarQueryParameter['sentence_id'] = sentenceId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Note
         * @param {number} id A unique integer value identifying this note.
         * @param {PatchedNote} [patchedNote] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        composerNotePartialUpdate: async (id: number, patchedNote?: PatchedNote, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('composerNotePartialUpdate', 'id', id)
            const localVarPath = `/api/composer/note/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication tokenAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication cookieAuth required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(patchedNote, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Note
         * @param {number} id A unique integer value identifying this note.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        composerNoteRetrieve: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('composerNoteRetrieve', 'id', id)
            const localVarPath = `/api/composer/note/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication tokenAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Tag
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        composerNoteTagList: async (limit?: number, offset?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/composer/note-tag/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication tokenAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication cookieAuth required

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Tag
         * @param {number} id A unique integer value identifying this tag.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        composerNoteTagRetrieve: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('composerNoteTagRetrieve', 'id', id)
            const localVarPath = `/api/composer/note-tag/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication tokenAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Note
         * @param {number} id A unique integer value identifying this note.
         * @param {Note} note 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        composerNoteUpdate: async (id: number, note: Note, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('composerNoteUpdate', 'id', id)
            // verify required parameter 'note' is not null or undefined
            assertParamExists('composerNoteUpdate', 'note', note)
            const localVarPath = `/api/composer/note/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication tokenAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication cookieAuth required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(note, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Phenotype
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        composerPhenotypeList: async (limit?: number, offset?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/composer/phenotype/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication tokenAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication cookieAuth required

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Phenotype
         * @param {number} id A unique integer value identifying this phenotype.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        composerPhenotypeRetrieve: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('composerPhenotypeRetrieve', 'id', id)
            const localVarPath = `/api/composer/phenotype/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication tokenAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * PopulationSet
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        composerPopulationList: async (limit?: number, offset?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/composer/population/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication tokenAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication cookieAuth required

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * PopulationSet
         * @param {number} id A unique integer value identifying this population set.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        composerPopulationRetrieve: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('composerPopulationRetrieve', 'id', id)
            const localVarPath = `/api/composer/population/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication tokenAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Profile
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        composerProfileMyRetrieve: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/composer/profile/my/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication tokenAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Projection Phenotype
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        composerProjectionList: async (limit?: number, offset?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/composer/projection/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication tokenAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication cookieAuth required

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Projection Phenotype
         * @param {number} id A unique integer value identifying this projection phenotype.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        composerProjectionRetrieve: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('composerProjectionRetrieve', 'id', id)
            const localVarPath = `/api/composer/projection/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication tokenAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Sentence
         * @param {number} id A unique integer value identifying this sentence.
         * @param {number} tagId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        composerSentenceAddTagCreate: async (id: number, tagId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('composerSentenceAddTagCreate', 'id', id)
            // verify required parameter 'tagId' is not null or undefined
            assertParamExists('composerSentenceAddTagCreate', 'tagId', tagId)
            const localVarPath = `/api/composer/sentence/{id}/add_tag/{tag_id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"tag_id"}}`, encodeURIComponent(String(tagId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication tokenAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Sentence
         * @param {number} id A unique integer value identifying this sentence.
         * @param {PatchedSentence} [patchedSentence] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        composerSentenceAssignOwnerPartialUpdate: async (id: number, patchedSentence?: PatchedSentence, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('composerSentenceAssignOwnerPartialUpdate', 'id', id)
            const localVarPath = `/api/composer/sentence/{id}/assign_owner/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication tokenAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication cookieAuth required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(patchedSentence, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns available users for assignment and possible state transitions for the selected items.
         * @param {Array<string>} [batchName] Multiple values may be separated by commas.
         * @param {Array<string>} [exclude] Multiple values may be separated by commas.
         * @param {Array<number>} [include] Multiple values may be separated by commas.
         * @param {boolean} [notes] Checks if entity has notes
         * @param {Array<ComposerSentenceAvailableOptionsRetrieveOrderingEnum>} [ordering] Ordering
         * @param {Array<ComposerSentenceAvailableOptionsRetrieveStateEnum>} [state] 
         * @param {Array<number>} [tags] 
         * @param {string} [title] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        composerSentenceAvailableOptionsRetrieve: async (batchName?: Array<string>, exclude?: Array<string>, include?: Array<number>, notes?: boolean, ordering?: Array<ComposerSentenceAvailableOptionsRetrieveOrderingEnum>, state?: Array<ComposerSentenceAvailableOptionsRetrieveStateEnum>, tags?: Array<number>, title?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/composer/sentence/available_options/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication tokenAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication cookieAuth required

            if (batchName) {
                localVarQueryParameter['batch_name'] = batchName.join(COLLECTION_FORMATS.csv);
            }

            if (exclude) {
                localVarQueryParameter['exclude'] = exclude.join(COLLECTION_FORMATS.csv);
            }

            if (include) {
                localVarQueryParameter['include'] = include.join(COLLECTION_FORMATS.csv);
            }

            if (notes !== undefined) {
                localVarQueryParameter['notes'] = notes;
            }

            if (ordering) {
                localVarQueryParameter['ordering'] = ordering.join(COLLECTION_FORMATS.csv);
            }

            if (state) {
                localVarQueryParameter['state'] = state;
            }

            if (tags) {
                localVarQueryParameter['tags'] = tags;
            }

            if (title !== undefined) {
                localVarQueryParameter['title'] = title;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns a list of all unique batch names in the database.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        composerSentenceBatchNamesRetrieve: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/composer/sentence/batch_names/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication tokenAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Apply a bulk action to the selected items and return the number of items updated successfully.
         * @param {Array<string>} [batchName] Multiple values may be separated by commas.
         * @param {Array<string>} [exclude] Multiple values may be separated by commas.
         * @param {Array<number>} [include] Multiple values may be separated by commas.
         * @param {boolean} [notes] Checks if entity has notes
         * @param {Array<ComposerSentenceBulkActionCreateOrderingEnum>} [ordering] Ordering
         * @param {Array<ComposerSentenceBulkActionCreateStateEnum>} [state] 
         * @param {Array<number>} [tags] 
         * @param {string} [title] 
         * @param {BulkAction} [bulkAction] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        composerSentenceBulkActionCreate: async (batchName?: Array<string>, exclude?: Array<string>, include?: Array<number>, notes?: boolean, ordering?: Array<ComposerSentenceBulkActionCreateOrderingEnum>, state?: Array<ComposerSentenceBulkActionCreateStateEnum>, tags?: Array<number>, title?: string, bulkAction?: BulkAction, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/composer/sentence/bulk_action/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication tokenAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication cookieAuth required

            if (batchName) {
                localVarQueryParameter['batch_name'] = batchName.join(COLLECTION_FORMATS.csv);
            }

            if (exclude) {
                localVarQueryParameter['exclude'] = exclude.join(COLLECTION_FORMATS.csv);
            }

            if (include) {
                localVarQueryParameter['include'] = include.join(COLLECTION_FORMATS.csv);
            }

            if (notes !== undefined) {
                localVarQueryParameter['notes'] = notes;
            }

            if (ordering) {
                localVarQueryParameter['ordering'] = ordering.join(COLLECTION_FORMATS.csv);
            }

            if (state) {
                localVarQueryParameter['state'] = state;
            }

            if (tags) {
                localVarQueryParameter['tags'] = tags;
            }

            if (title !== undefined) {
                localVarQueryParameter['title'] = title;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(bulkAction, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Sentence
         * @param {Sentence} sentence 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        composerSentenceCreate: async (sentence: Sentence, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'sentence' is not null or undefined
            assertParamExists('composerSentenceCreate', 'sentence', sentence)
            const localVarPath = `/api/composer/sentence/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication tokenAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication cookieAuth required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(sentence, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Sentence
         * @param {number} id A unique integer value identifying this sentence.
         * @param {number} tagId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        composerSentenceDelTagCreate: async (id: number, tagId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('composerSentenceDelTagCreate', 'id', id)
            // verify required parameter 'tagId' is not null or undefined
            assertParamExists('composerSentenceDelTagCreate', 'tagId', tagId)
            const localVarPath = `/api/composer/sentence/{id}/del_tag/{tag_id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"tag_id"}}`, encodeURIComponent(String(tagId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication tokenAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Sentence
         * @param {number} id A unique integer value identifying this sentence.
         * @param {string} transition 
         * @param {Sentence} sentence 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        composerSentenceDoTransitionCreate: async (id: number, transition: string, sentence: Sentence, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('composerSentenceDoTransitionCreate', 'id', id)
            // verify required parameter 'transition' is not null or undefined
            assertParamExists('composerSentenceDoTransitionCreate', 'transition', transition)
            // verify required parameter 'sentence' is not null or undefined
            assertParamExists('composerSentenceDoTransitionCreate', 'sentence', sentence)
            const localVarPath = `/api/composer/sentence/{id}/do_transition/{transition}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"transition"}}`, encodeURIComponent(String(transition)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication tokenAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication cookieAuth required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(sentence, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Sentence
         * @param {Array<string>} [batchName] Multiple values may be separated by commas.
         * @param {Array<string>} [exclude] Multiple values may be separated by commas.
         * @param {Array<number>} [include] Multiple values may be separated by commas.
         * @param {number} [limit] Number of results to return per page.
         * @param {boolean} [notes] Checks if entity has notes
         * @param {number} [offset] The initial index from which to return the results.
         * @param {Array<ComposerSentenceListOrderingEnum>} [ordering] Ordering
         * @param {Array<ComposerSentenceListStateEnum>} [state] 
         * @param {Array<number>} [tags] 
         * @param {string} [title] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        composerSentenceList: async (batchName?: Array<string>, exclude?: Array<string>, include?: Array<number>, limit?: number, notes?: boolean, offset?: number, ordering?: Array<ComposerSentenceListOrderingEnum>, state?: Array<ComposerSentenceListStateEnum>, tags?: Array<number>, title?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/composer/sentence/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication tokenAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication cookieAuth required

            if (batchName) {
                localVarQueryParameter['batch_name'] = batchName.join(COLLECTION_FORMATS.csv);
            }

            if (exclude) {
                localVarQueryParameter['exclude'] = exclude.join(COLLECTION_FORMATS.csv);
            }

            if (include) {
                localVarQueryParameter['include'] = include.join(COLLECTION_FORMATS.csv);
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (notes !== undefined) {
                localVarQueryParameter['notes'] = notes;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (ordering) {
                localVarQueryParameter['ordering'] = ordering.join(COLLECTION_FORMATS.csv);
            }

            if (state) {
                localVarQueryParameter['state'] = state;
            }

            if (tags) {
                localVarQueryParameter['tags'] = tags;
            }

            if (title !== undefined) {
                localVarQueryParameter['title'] = title;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Sentence
         * @param {number} id A unique integer value identifying this sentence.
         * @param {PatchedSentence} [patchedSentence] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        composerSentencePartialUpdate: async (id: number, patchedSentence?: PatchedSentence, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('composerSentencePartialUpdate', 'id', id)
            const localVarPath = `/api/composer/sentence/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication tokenAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication cookieAuth required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(patchedSentence, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Sentence
         * @param {number} id A unique integer value identifying this sentence.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        composerSentenceRetrieve: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('composerSentenceRetrieve', 'id', id)
            const localVarPath = `/api/composer/sentence/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication tokenAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Sentence
         * @param {number} id A unique integer value identifying this sentence.
         * @param {Sentence} sentence 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        composerSentenceUpdate: async (id: number, sentence: Sentence, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('composerSentenceUpdate', 'id', id)
            // verify required parameter 'sentence' is not null or undefined
            assertParamExists('composerSentenceUpdate', 'sentence', sentence)
            const localVarPath = `/api/composer/sentence/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication tokenAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication cookieAuth required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(sentence, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Sex
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        composerSexList: async (limit?: number, offset?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/composer/sex/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication tokenAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication cookieAuth required

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Sex
         * @param {number} id A unique integer value identifying this sex.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        composerSexRetrieve: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('composerSexRetrieve', 'id', id)
            const localVarPath = `/api/composer/sex/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication tokenAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Specie
         * @param {number} [limit] Number of results to return per page.
         * @param {string} [name] 
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        composerSpecieList: async (limit?: number, name?: string, offset?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/composer/specie/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication tokenAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication cookieAuth required

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Specie
         * @param {number} id A unique integer value identifying this specie.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        composerSpecieRetrieve: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('composerSpecieRetrieve', 'id', id)
            const localVarPath = `/api/composer/specie/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication tokenAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * StatementAlert
         * @param {StatementAlert} statementAlert 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        composerStatementAlertCreate: async (statementAlert: StatementAlert, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'statementAlert' is not null or undefined
            assertParamExists('composerStatementAlertCreate', 'statementAlert', statementAlert)
            const localVarPath = `/api/composer/statementAlert/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication tokenAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication cookieAuth required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(statementAlert, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * StatementAlert
         * @param {number} id A unique integer value identifying this statement alert.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        composerStatementAlertDestroy: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('composerStatementAlertDestroy', 'id', id)
            const localVarPath = `/api/composer/statementAlert/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication tokenAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * StatementAlert
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        composerStatementAlertList: async (limit?: number, offset?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/composer/statementAlert/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication tokenAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication cookieAuth required

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * StatementAlert
         * @param {number} id A unique integer value identifying this statement alert.
         * @param {PatchedStatementAlert} [patchedStatementAlert] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        composerStatementAlertPartialUpdate: async (id: number, patchedStatementAlert?: PatchedStatementAlert, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('composerStatementAlertPartialUpdate', 'id', id)
            const localVarPath = `/api/composer/statementAlert/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication tokenAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication cookieAuth required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(patchedStatementAlert, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * StatementAlert
         * @param {number} id A unique integer value identifying this statement alert.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        composerStatementAlertRetrieve: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('composerStatementAlertRetrieve', 'id', id)
            const localVarPath = `/api/composer/statementAlert/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication tokenAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * StatementAlert
         * @param {number} id A unique integer value identifying this statement alert.
         * @param {StatementAlert} statementAlert 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        composerStatementAlertUpdate: async (id: number, statementAlert: StatementAlert, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('composerStatementAlertUpdate', 'id', id)
            // verify required parameter 'statementAlert' is not null or undefined
            assertParamExists('composerStatementAlertUpdate', 'statementAlert', statementAlert)
            const localVarPath = `/api/composer/statementAlert/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication tokenAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication cookieAuth required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(statementAlert, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Tag
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        composerTagList: async (limit?: number, offset?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/composer/tag/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication tokenAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication cookieAuth required

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Tag
         * @param {number} id A unique integer value identifying this tag.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        composerTagRetrieve: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('composerTagRetrieve', 'id', id)
            const localVarPath = `/api/composer/tag/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication tokenAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Via
         * @param {Via} via 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        composerViaCreate: async (via: Via, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'via' is not null or undefined
            assertParamExists('composerViaCreate', 'via', via)
            const localVarPath = `/api/composer/via/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication tokenAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication cookieAuth required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(via, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Via
         * @param {number} id A unique integer value identifying this via.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        composerViaDestroy: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('composerViaDestroy', 'id', id)
            const localVarPath = `/api/composer/via/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication tokenAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Via
         * @param {number} [connectivityStatementId] 
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        composerViaList: async (connectivityStatementId?: number, limit?: number, offset?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/composer/via/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication tokenAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication cookieAuth required

            if (connectivityStatementId !== undefined) {
                localVarQueryParameter['connectivity_statement_id'] = connectivityStatementId;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Via
         * @param {number} id A unique integer value identifying this via.
         * @param {PatchedVia} [patchedVia] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        composerViaPartialUpdate: async (id: number, patchedVia?: PatchedVia, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('composerViaPartialUpdate', 'id', id)
            const localVarPath = `/api/composer/via/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication tokenAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication cookieAuth required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(patchedVia, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Via
         * @param {number} id A unique integer value identifying this via.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        composerViaRetrieve: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('composerViaRetrieve', 'id', id)
            const localVarPath = `/api/composer/via/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication tokenAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Via
         * @param {number} id A unique integer value identifying this via.
         * @param {Via} via 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        composerViaUpdate: async (id: number, via: Via, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('composerViaUpdate', 'id', id)
            // verify required parameter 'via' is not null or undefined
            assertParamExists('composerViaUpdate', 'via', via)
            const localVarPath = `/api/composer/via/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication tokenAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication cookieAuth required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(via, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ComposerApi - functional programming interface
 * @export
 */
export const ComposerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ComposerApiAxiosParamCreator(configuration)
    return {
        /**
         * A viewset for viewing the list of alert types.
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async composerAlertList(limit?: number, offset?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedAlertTypeList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.composerAlertList(limit, offset, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ComposerApi.composerAlertList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * A viewset for viewing the list of alert types.
         * @param {number} id A unique integer value identifying this alert type.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async composerAlertRetrieve(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AlertType>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.composerAlertRetrieve(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ComposerApi.composerAlertRetrieve']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * AnatomicalEntity
         * @param {Array<number>} [excludeIds] Multiple values may be separated by commas.
         * @param {number} [limit] Number of results to return per page.
         * @param {string} [name] 
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async composerAnatomicalEntityList(excludeIds?: Array<number>, limit?: number, name?: string, offset?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedAnatomicalEntityList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.composerAnatomicalEntityList(excludeIds, limit, name, offset, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ComposerApi.composerAnatomicalEntityList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * AnatomicalEntity
         * @param {number} id A unique integer value identifying this Anatomical Entity.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async composerAnatomicalEntityRetrieve(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AnatomicalEntity>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.composerAnatomicalEntityRetrieve(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ComposerApi.composerAnatomicalEntityRetrieve']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * ConnectivityStatement
         * @param {number} id A unique integer value identifying this connectivity statement.
         * @param {string} uri 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async composerConnectivityStatementAddProvenanceCreate(id: number, uri: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ConnectivityStatement>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.composerConnectivityStatementAddProvenanceCreate(id, uri, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ComposerApi.composerConnectivityStatementAddProvenanceCreate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * ConnectivityStatement
         * @param {number} id A unique integer value identifying this connectivity statement.
         * @param {number} specieId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async composerConnectivityStatementAddSpecieCreate(id: number, specieId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ConnectivityStatement>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.composerConnectivityStatementAddSpecieCreate(id, specieId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ComposerApi.composerConnectivityStatementAddSpecieCreate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * ConnectivityStatement
         * @param {number} id A unique integer value identifying this connectivity statement.
         * @param {number} tagId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async composerConnectivityStatementAddTagCreate(id: number, tagId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ConnectivityStatement>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.composerConnectivityStatementAddTagCreate(id, tagId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ComposerApi.composerConnectivityStatementAddTagCreate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * ConnectivityStatement
         * @param {number} id A unique integer value identifying this connectivity statement.
         * @param {PatchedConnectivityStatement} [patchedConnectivityStatement] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async composerConnectivityStatementAssignOwnerPartialUpdate(id: number, patchedConnectivityStatement?: PatchedConnectivityStatement, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ConnectivityStatement>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.composerConnectivityStatementAssignOwnerPartialUpdate(id, patchedConnectivityStatement, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ComposerApi.composerConnectivityStatementAssignOwnerPartialUpdate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Returns available users for assignment and possible state transitions for the selected items.
         * @param {Array<number>} [destinations] 
         * @param {Array<number>} [excludeIds] Multiple values may be separated by commas.
         * @param {number} [excludeSentenceId] 
         * @param {boolean} [hasStatementBeenExported] Is Exported
         * @param {Array<number>} [include] Multiple values may be separated by commas.
         * @param {string} [knowledgeStatement] 
         * @param {boolean} [notes] Checks if entity has notes
         * @param {Array<ComposerConnectivityStatementAvailableOptionsRetrieveOrderingEnum>} [ordering] Ordering
         * @param {Array<number>} [origins] 
         * @param {Array<number>} [populationset] 
         * @param {number} [sentenceId] 
         * @param {Array<ComposerConnectivityStatementAvailableOptionsRetrieveStateEnum>} [state] 
         * @param {Array<number>} [tags] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async composerConnectivityStatementAvailableOptionsRetrieve(destinations?: Array<number>, excludeIds?: Array<number>, excludeSentenceId?: number, hasStatementBeenExported?: boolean, include?: Array<number>, knowledgeStatement?: string, notes?: boolean, ordering?: Array<ComposerConnectivityStatementAvailableOptionsRetrieveOrderingEnum>, origins?: Array<number>, populationset?: Array<number>, sentenceId?: number, state?: Array<ComposerConnectivityStatementAvailableOptionsRetrieveStateEnum>, tags?: Array<number>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ConnectivityStatement>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.composerConnectivityStatementAvailableOptionsRetrieve(destinations, excludeIds, excludeSentenceId, hasStatementBeenExported, include, knowledgeStatement, notes, ordering, origins, populationset, sentenceId, state, tags, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ComposerApi.composerConnectivityStatementAvailableOptionsRetrieve']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Apply a bulk action to the selected items and return the number of items updated successfully.
         * @param {Array<number>} [destinations] 
         * @param {Array<number>} [excludeIds] Multiple values may be separated by commas.
         * @param {number} [excludeSentenceId] 
         * @param {boolean} [hasStatementBeenExported] Is Exported
         * @param {Array<number>} [include] Multiple values may be separated by commas.
         * @param {string} [knowledgeStatement] 
         * @param {boolean} [notes] Checks if entity has notes
         * @param {Array<ComposerConnectivityStatementBulkActionCreateOrderingEnum>} [ordering] Ordering
         * @param {Array<number>} [origins] 
         * @param {Array<number>} [populationset] 
         * @param {number} [sentenceId] 
         * @param {Array<ComposerConnectivityStatementBulkActionCreateStateEnum>} [state] 
         * @param {Array<number>} [tags] 
         * @param {BulkAction} [bulkAction] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async composerConnectivityStatementBulkActionCreate(destinations?: Array<number>, excludeIds?: Array<number>, excludeSentenceId?: number, hasStatementBeenExported?: boolean, include?: Array<number>, knowledgeStatement?: string, notes?: boolean, ordering?: Array<ComposerConnectivityStatementBulkActionCreateOrderingEnum>, origins?: Array<number>, populationset?: Array<number>, sentenceId?: number, state?: Array<ComposerConnectivityStatementBulkActionCreateStateEnum>, tags?: Array<number>, bulkAction?: BulkAction, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BulkActionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.composerConnectivityStatementBulkActionCreate(destinations, excludeIds, excludeSentenceId, hasStatementBeenExported, include, knowledgeStatement, notes, ordering, origins, populationset, sentenceId, state, tags, bulkAction, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ComposerApi.composerConnectivityStatementBulkActionCreate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * ConnectivityStatement
         * @param {number} id A unique integer value identifying this connectivity statement.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async composerConnectivityStatementCloneStatementRetrieve(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ConnectivityStatement>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.composerConnectivityStatementCloneStatementRetrieve(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ComposerApi.composerConnectivityStatementCloneStatementRetrieve']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * ConnectivityStatement
         * @param {ConnectivityStatement} [connectivityStatement] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async composerConnectivityStatementCreate(connectivityStatement?: ConnectivityStatement, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ConnectivityStatement>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.composerConnectivityStatementCreate(connectivityStatement, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ComposerApi.composerConnectivityStatementCreate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * ConnectivityStatement
         * @param {number} id A unique integer value identifying this connectivity statement.
         * @param {number} provenanceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async composerConnectivityStatementDelProvenanceDestroy(id: number, provenanceId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.composerConnectivityStatementDelProvenanceDestroy(id, provenanceId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ComposerApi.composerConnectivityStatementDelProvenanceDestroy']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * ConnectivityStatement
         * @param {number} id A unique integer value identifying this connectivity statement.
         * @param {number} specieId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async composerConnectivityStatementDelSpecieCreate(id: number, specieId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ConnectivityStatement>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.composerConnectivityStatementDelSpecieCreate(id, specieId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ComposerApi.composerConnectivityStatementDelSpecieCreate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * ConnectivityStatement
         * @param {number} id A unique integer value identifying this connectivity statement.
         * @param {number} tagId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async composerConnectivityStatementDelTagCreate(id: number, tagId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ConnectivityStatement>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.composerConnectivityStatementDelTagCreate(id, tagId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ComposerApi.composerConnectivityStatementDelTagCreate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * ConnectivityStatement
         * @param {number} id A unique integer value identifying this connectivity statement.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async composerConnectivityStatementDestroy(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.composerConnectivityStatementDestroy(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ComposerApi.composerConnectivityStatementDestroy']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * ConnectivityStatement
         * @param {number} id A unique integer value identifying this connectivity statement.
         * @param {string} transition 
         * @param {ConnectivityStatement} [connectivityStatement] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async composerConnectivityStatementDoTransitionCreate(id: number, transition: string, connectivityStatement?: ConnectivityStatement, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ConnectivityStatement>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.composerConnectivityStatementDoTransitionCreate(id, transition, connectivityStatement, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ComposerApi.composerConnectivityStatementDoTransitionCreate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * ConnectivityStatement
         * @param {Array<number>} [destinations] 
         * @param {Array<number>} [excludeIds] Multiple values may be separated by commas.
         * @param {number} [excludeSentenceId] 
         * @param {boolean} [hasStatementBeenExported] Is Exported
         * @param {Array<number>} [include] Multiple values may be separated by commas.
         * @param {string} [knowledgeStatement] 
         * @param {number} [limit] Number of results to return per page.
         * @param {boolean} [notes] Checks if entity has notes
         * @param {number} [offset] The initial index from which to return the results.
         * @param {Array<ComposerConnectivityStatementListOrderingEnum>} [ordering] Ordering
         * @param {Array<number>} [origins] 
         * @param {Array<number>} [populationset] 
         * @param {number} [sentenceId] 
         * @param {Array<ComposerConnectivityStatementListStateEnum>} [state] 
         * @param {Array<number>} [tags] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async composerConnectivityStatementList(destinations?: Array<number>, excludeIds?: Array<number>, excludeSentenceId?: number, hasStatementBeenExported?: boolean, include?: Array<number>, knowledgeStatement?: string, limit?: number, notes?: boolean, offset?: number, ordering?: Array<ComposerConnectivityStatementListOrderingEnum>, origins?: Array<number>, populationset?: Array<number>, sentenceId?: number, state?: Array<ComposerConnectivityStatementListStateEnum>, tags?: Array<number>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedBaseConnectivityStatementList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.composerConnectivityStatementList(destinations, excludeIds, excludeSentenceId, hasStatementBeenExported, include, knowledgeStatement, limit, notes, offset, ordering, origins, populationset, sentenceId, state, tags, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ComposerApi.composerConnectivityStatementList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * ConnectivityStatement
         * @param {number} id A unique integer value identifying this connectivity statement.
         * @param {PatchedConnectivityStatementUpdate} [patchedConnectivityStatementUpdate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async composerConnectivityStatementPartialUpdate(id: number, patchedConnectivityStatementUpdate?: PatchedConnectivityStatementUpdate, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ConnectivityStatement>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.composerConnectivityStatementPartialUpdate(id, patchedConnectivityStatementUpdate, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ComposerApi.composerConnectivityStatementPartialUpdate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * ConnectivityStatement
         * @param {number} id A unique integer value identifying this connectivity statement.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async composerConnectivityStatementRetrieve(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ConnectivityStatement>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.composerConnectivityStatementRetrieve(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ComposerApi.composerConnectivityStatementRetrieve']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * ConnectivityStatement
         * @param {number} id A unique integer value identifying this connectivity statement.
         * @param {ConnectivityStatementUpdate} connectivityStatementUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async composerConnectivityStatementUpdate(id: number, connectivityStatementUpdate: ConnectivityStatementUpdate, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ConnectivityStatement>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.composerConnectivityStatementUpdate(id, connectivityStatementUpdate, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ComposerApi.composerConnectivityStatementUpdate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Destination
         * @param {Destination} destination 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async composerDestinationCreate(destination: Destination, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Destination>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.composerDestinationCreate(destination, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ComposerApi.composerDestinationCreate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Destination
         * @param {number} id A unique integer value identifying this destination.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async composerDestinationDestroy(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.composerDestinationDestroy(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ComposerApi.composerDestinationDestroy']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Destination
         * @param {number} [connectivityStatementId] 
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async composerDestinationList(connectivityStatementId?: number, limit?: number, offset?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedDestinationList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.composerDestinationList(connectivityStatementId, limit, offset, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ComposerApi.composerDestinationList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Destination
         * @param {number} id A unique integer value identifying this destination.
         * @param {PatchedDestination} [patchedDestination] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async composerDestinationPartialUpdate(id: number, patchedDestination?: PatchedDestination, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Destination>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.composerDestinationPartialUpdate(id, patchedDestination, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ComposerApi.composerDestinationPartialUpdate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Destination
         * @param {number} id A unique integer value identifying this destination.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async composerDestinationRetrieve(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Destination>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.composerDestinationRetrieve(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ComposerApi.composerDestinationRetrieve']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Destination
         * @param {number} id A unique integer value identifying this destination.
         * @param {Destination} destination 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async composerDestinationUpdate(id: number, destination: Destination, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Destination>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.composerDestinationUpdate(id, destination, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ComposerApi.composerDestinationUpdate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async composerJsonschemasRetrieve(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: any; }>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.composerJsonschemasRetrieve(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ComposerApi.composerJsonschemasRetrieve']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Note
         * @param {Note} note 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async composerNoteCreate(note: Note, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Note>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.composerNoteCreate(note, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ComposerApi.composerNoteCreate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Note
         * @param {number} id A unique integer value identifying this note.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async composerNoteDestroy(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.composerNoteDestroy(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ComposerApi.composerNoteDestroy']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Note
         * @param {number | null} [connectivityStatementId] 
         * @param {boolean} [includeSystemNotes] Include System Notes
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {number | null} [sentenceId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async composerNoteList(connectivityStatementId?: number | null, includeSystemNotes?: boolean, limit?: number, offset?: number, sentenceId?: number | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedNoteList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.composerNoteList(connectivityStatementId, includeSystemNotes, limit, offset, sentenceId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ComposerApi.composerNoteList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Note
         * @param {number} id A unique integer value identifying this note.
         * @param {PatchedNote} [patchedNote] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async composerNotePartialUpdate(id: number, patchedNote?: PatchedNote, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Note>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.composerNotePartialUpdate(id, patchedNote, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ComposerApi.composerNotePartialUpdate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Note
         * @param {number} id A unique integer value identifying this note.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async composerNoteRetrieve(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Note>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.composerNoteRetrieve(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ComposerApi.composerNoteRetrieve']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Tag
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async composerNoteTagList(limit?: number, offset?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedTagList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.composerNoteTagList(limit, offset, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ComposerApi.composerNoteTagList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Tag
         * @param {number} id A unique integer value identifying this tag.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async composerNoteTagRetrieve(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Tag>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.composerNoteTagRetrieve(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ComposerApi.composerNoteTagRetrieve']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Note
         * @param {number} id A unique integer value identifying this note.
         * @param {Note} note 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async composerNoteUpdate(id: number, note: Note, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Note>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.composerNoteUpdate(id, note, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ComposerApi.composerNoteUpdate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Phenotype
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async composerPhenotypeList(limit?: number, offset?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedPhenotypeList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.composerPhenotypeList(limit, offset, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ComposerApi.composerPhenotypeList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Phenotype
         * @param {number} id A unique integer value identifying this phenotype.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async composerPhenotypeRetrieve(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Phenotype>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.composerPhenotypeRetrieve(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ComposerApi.composerPhenotypeRetrieve']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * PopulationSet
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async composerPopulationList(limit?: number, offset?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedPopulationSetList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.composerPopulationList(limit, offset, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ComposerApi.composerPopulationList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * PopulationSet
         * @param {number} id A unique integer value identifying this population set.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async composerPopulationRetrieve(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PopulationSet>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.composerPopulationRetrieve(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ComposerApi.composerPopulationRetrieve']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Profile
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async composerProfileMyRetrieve(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Profile>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.composerProfileMyRetrieve(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ComposerApi.composerProfileMyRetrieve']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Projection Phenotype
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async composerProjectionList(limit?: number, offset?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedProjectionPhenotypeList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.composerProjectionList(limit, offset, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ComposerApi.composerProjectionList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Projection Phenotype
         * @param {number} id A unique integer value identifying this projection phenotype.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async composerProjectionRetrieve(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProjectionPhenotype>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.composerProjectionRetrieve(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ComposerApi.composerProjectionRetrieve']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Sentence
         * @param {number} id A unique integer value identifying this sentence.
         * @param {number} tagId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async composerSentenceAddTagCreate(id: number, tagId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Sentence>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.composerSentenceAddTagCreate(id, tagId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ComposerApi.composerSentenceAddTagCreate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Sentence
         * @param {number} id A unique integer value identifying this sentence.
         * @param {PatchedSentence} [patchedSentence] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async composerSentenceAssignOwnerPartialUpdate(id: number, patchedSentence?: PatchedSentence, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Sentence>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.composerSentenceAssignOwnerPartialUpdate(id, patchedSentence, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ComposerApi.composerSentenceAssignOwnerPartialUpdate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Returns available users for assignment and possible state transitions for the selected items.
         * @param {Array<string>} [batchName] Multiple values may be separated by commas.
         * @param {Array<string>} [exclude] Multiple values may be separated by commas.
         * @param {Array<number>} [include] Multiple values may be separated by commas.
         * @param {boolean} [notes] Checks if entity has notes
         * @param {Array<ComposerSentenceAvailableOptionsRetrieveOrderingEnum>} [ordering] Ordering
         * @param {Array<ComposerSentenceAvailableOptionsRetrieveStateEnum>} [state] 
         * @param {Array<number>} [tags] 
         * @param {string} [title] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async composerSentenceAvailableOptionsRetrieve(batchName?: Array<string>, exclude?: Array<string>, include?: Array<number>, notes?: boolean, ordering?: Array<ComposerSentenceAvailableOptionsRetrieveOrderingEnum>, state?: Array<ComposerSentenceAvailableOptionsRetrieveStateEnum>, tags?: Array<number>, title?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Sentence>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.composerSentenceAvailableOptionsRetrieve(batchName, exclude, include, notes, ordering, state, tags, title, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ComposerApi.composerSentenceAvailableOptionsRetrieve']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Returns a list of all unique batch names in the database.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async composerSentenceBatchNamesRetrieve(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Sentence>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.composerSentenceBatchNamesRetrieve(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ComposerApi.composerSentenceBatchNamesRetrieve']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Apply a bulk action to the selected items and return the number of items updated successfully.
         * @param {Array<string>} [batchName] Multiple values may be separated by commas.
         * @param {Array<string>} [exclude] Multiple values may be separated by commas.
         * @param {Array<number>} [include] Multiple values may be separated by commas.
         * @param {boolean} [notes] Checks if entity has notes
         * @param {Array<ComposerSentenceBulkActionCreateOrderingEnum>} [ordering] Ordering
         * @param {Array<ComposerSentenceBulkActionCreateStateEnum>} [state] 
         * @param {Array<number>} [tags] 
         * @param {string} [title] 
         * @param {BulkAction} [bulkAction] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async composerSentenceBulkActionCreate(batchName?: Array<string>, exclude?: Array<string>, include?: Array<number>, notes?: boolean, ordering?: Array<ComposerSentenceBulkActionCreateOrderingEnum>, state?: Array<ComposerSentenceBulkActionCreateStateEnum>, tags?: Array<number>, title?: string, bulkAction?: BulkAction, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BulkActionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.composerSentenceBulkActionCreate(batchName, exclude, include, notes, ordering, state, tags, title, bulkAction, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ComposerApi.composerSentenceBulkActionCreate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Sentence
         * @param {Sentence} sentence 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async composerSentenceCreate(sentence: Sentence, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Sentence>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.composerSentenceCreate(sentence, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ComposerApi.composerSentenceCreate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Sentence
         * @param {number} id A unique integer value identifying this sentence.
         * @param {number} tagId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async composerSentenceDelTagCreate(id: number, tagId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Sentence>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.composerSentenceDelTagCreate(id, tagId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ComposerApi.composerSentenceDelTagCreate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Sentence
         * @param {number} id A unique integer value identifying this sentence.
         * @param {string} transition 
         * @param {Sentence} sentence 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async composerSentenceDoTransitionCreate(id: number, transition: string, sentence: Sentence, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Sentence>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.composerSentenceDoTransitionCreate(id, transition, sentence, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ComposerApi.composerSentenceDoTransitionCreate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Sentence
         * @param {Array<string>} [batchName] Multiple values may be separated by commas.
         * @param {Array<string>} [exclude] Multiple values may be separated by commas.
         * @param {Array<number>} [include] Multiple values may be separated by commas.
         * @param {number} [limit] Number of results to return per page.
         * @param {boolean} [notes] Checks if entity has notes
         * @param {number} [offset] The initial index from which to return the results.
         * @param {Array<ComposerSentenceListOrderingEnum>} [ordering] Ordering
         * @param {Array<ComposerSentenceListStateEnum>} [state] 
         * @param {Array<number>} [tags] 
         * @param {string} [title] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async composerSentenceList(batchName?: Array<string>, exclude?: Array<string>, include?: Array<number>, limit?: number, notes?: boolean, offset?: number, ordering?: Array<ComposerSentenceListOrderingEnum>, state?: Array<ComposerSentenceListStateEnum>, tags?: Array<number>, title?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedSentenceList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.composerSentenceList(batchName, exclude, include, limit, notes, offset, ordering, state, tags, title, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ComposerApi.composerSentenceList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Sentence
         * @param {number} id A unique integer value identifying this sentence.
         * @param {PatchedSentence} [patchedSentence] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async composerSentencePartialUpdate(id: number, patchedSentence?: PatchedSentence, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Sentence>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.composerSentencePartialUpdate(id, patchedSentence, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ComposerApi.composerSentencePartialUpdate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Sentence
         * @param {number} id A unique integer value identifying this sentence.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async composerSentenceRetrieve(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Sentence>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.composerSentenceRetrieve(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ComposerApi.composerSentenceRetrieve']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Sentence
         * @param {number} id A unique integer value identifying this sentence.
         * @param {Sentence} sentence 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async composerSentenceUpdate(id: number, sentence: Sentence, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Sentence>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.composerSentenceUpdate(id, sentence, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ComposerApi.composerSentenceUpdate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Sex
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async composerSexList(limit?: number, offset?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedSexList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.composerSexList(limit, offset, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ComposerApi.composerSexList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Sex
         * @param {number} id A unique integer value identifying this sex.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async composerSexRetrieve(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Sex>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.composerSexRetrieve(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ComposerApi.composerSexRetrieve']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Specie
         * @param {number} [limit] Number of results to return per page.
         * @param {string} [name] 
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async composerSpecieList(limit?: number, name?: string, offset?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedSpecieList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.composerSpecieList(limit, name, offset, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ComposerApi.composerSpecieList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Specie
         * @param {number} id A unique integer value identifying this specie.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async composerSpecieRetrieve(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Specie>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.composerSpecieRetrieve(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ComposerApi.composerSpecieRetrieve']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * StatementAlert
         * @param {StatementAlert} statementAlert 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async composerStatementAlertCreate(statementAlert: StatementAlert, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StatementAlert>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.composerStatementAlertCreate(statementAlert, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ComposerApi.composerStatementAlertCreate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * StatementAlert
         * @param {number} id A unique integer value identifying this statement alert.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async composerStatementAlertDestroy(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.composerStatementAlertDestroy(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ComposerApi.composerStatementAlertDestroy']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * StatementAlert
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async composerStatementAlertList(limit?: number, offset?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedStatementAlertList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.composerStatementAlertList(limit, offset, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ComposerApi.composerStatementAlertList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * StatementAlert
         * @param {number} id A unique integer value identifying this statement alert.
         * @param {PatchedStatementAlert} [patchedStatementAlert] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async composerStatementAlertPartialUpdate(id: number, patchedStatementAlert?: PatchedStatementAlert, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StatementAlert>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.composerStatementAlertPartialUpdate(id, patchedStatementAlert, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ComposerApi.composerStatementAlertPartialUpdate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * StatementAlert
         * @param {number} id A unique integer value identifying this statement alert.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async composerStatementAlertRetrieve(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StatementAlert>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.composerStatementAlertRetrieve(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ComposerApi.composerStatementAlertRetrieve']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * StatementAlert
         * @param {number} id A unique integer value identifying this statement alert.
         * @param {StatementAlert} statementAlert 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async composerStatementAlertUpdate(id: number, statementAlert: StatementAlert, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StatementAlert>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.composerStatementAlertUpdate(id, statementAlert, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ComposerApi.composerStatementAlertUpdate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Tag
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async composerTagList(limit?: number, offset?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedTagList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.composerTagList(limit, offset, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ComposerApi.composerTagList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Tag
         * @param {number} id A unique integer value identifying this tag.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async composerTagRetrieve(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Tag>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.composerTagRetrieve(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ComposerApi.composerTagRetrieve']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Via
         * @param {Via} via 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async composerViaCreate(via: Via, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Via>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.composerViaCreate(via, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ComposerApi.composerViaCreate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Via
         * @param {number} id A unique integer value identifying this via.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async composerViaDestroy(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.composerViaDestroy(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ComposerApi.composerViaDestroy']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Via
         * @param {number} [connectivityStatementId] 
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async composerViaList(connectivityStatementId?: number, limit?: number, offset?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedViaList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.composerViaList(connectivityStatementId, limit, offset, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ComposerApi.composerViaList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Via
         * @param {number} id A unique integer value identifying this via.
         * @param {PatchedVia} [patchedVia] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async composerViaPartialUpdate(id: number, patchedVia?: PatchedVia, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Via>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.composerViaPartialUpdate(id, patchedVia, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ComposerApi.composerViaPartialUpdate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Via
         * @param {number} id A unique integer value identifying this via.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async composerViaRetrieve(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Via>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.composerViaRetrieve(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ComposerApi.composerViaRetrieve']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Via
         * @param {number} id A unique integer value identifying this via.
         * @param {Via} via 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async composerViaUpdate(id: number, via: Via, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Via>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.composerViaUpdate(id, via, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ComposerApi.composerViaUpdate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * ComposerApi - factory interface
 * @export
 */
export const ComposerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ComposerApiFp(configuration)
    return {
        /**
         * A viewset for viewing the list of alert types.
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        composerAlertList(limit?: number, offset?: number, options?: RawAxiosRequestConfig): AxiosPromise<PaginatedAlertTypeList> {
            return localVarFp.composerAlertList(limit, offset, options).then((request) => request(axios, basePath));
        },
        /**
         * A viewset for viewing the list of alert types.
         * @param {number} id A unique integer value identifying this alert type.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        composerAlertRetrieve(id: number, options?: RawAxiosRequestConfig): AxiosPromise<AlertType> {
            return localVarFp.composerAlertRetrieve(id, options).then((request) => request(axios, basePath));
        },
        /**
         * AnatomicalEntity
         * @param {Array<number>} [excludeIds] Multiple values may be separated by commas.
         * @param {number} [limit] Number of results to return per page.
         * @param {string} [name] 
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        composerAnatomicalEntityList(excludeIds?: Array<number>, limit?: number, name?: string, offset?: number, options?: RawAxiosRequestConfig): AxiosPromise<PaginatedAnatomicalEntityList> {
            return localVarFp.composerAnatomicalEntityList(excludeIds, limit, name, offset, options).then((request) => request(axios, basePath));
        },
        /**
         * AnatomicalEntity
         * @param {number} id A unique integer value identifying this Anatomical Entity.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        composerAnatomicalEntityRetrieve(id: number, options?: RawAxiosRequestConfig): AxiosPromise<AnatomicalEntity> {
            return localVarFp.composerAnatomicalEntityRetrieve(id, options).then((request) => request(axios, basePath));
        },
        /**
         * ConnectivityStatement
         * @param {number} id A unique integer value identifying this connectivity statement.
         * @param {string} uri 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        composerConnectivityStatementAddProvenanceCreate(id: number, uri: string, options?: RawAxiosRequestConfig): AxiosPromise<ConnectivityStatement> {
            return localVarFp.composerConnectivityStatementAddProvenanceCreate(id, uri, options).then((request) => request(axios, basePath));
        },
        /**
         * ConnectivityStatement
         * @param {number} id A unique integer value identifying this connectivity statement.
         * @param {number} specieId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        composerConnectivityStatementAddSpecieCreate(id: number, specieId: number, options?: RawAxiosRequestConfig): AxiosPromise<ConnectivityStatement> {
            return localVarFp.composerConnectivityStatementAddSpecieCreate(id, specieId, options).then((request) => request(axios, basePath));
        },
        /**
         * ConnectivityStatement
         * @param {number} id A unique integer value identifying this connectivity statement.
         * @param {number} tagId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        composerConnectivityStatementAddTagCreate(id: number, tagId: number, options?: RawAxiosRequestConfig): AxiosPromise<ConnectivityStatement> {
            return localVarFp.composerConnectivityStatementAddTagCreate(id, tagId, options).then((request) => request(axios, basePath));
        },
        /**
         * ConnectivityStatement
         * @param {number} id A unique integer value identifying this connectivity statement.
         * @param {PatchedConnectivityStatement} [patchedConnectivityStatement] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        composerConnectivityStatementAssignOwnerPartialUpdate(id: number, patchedConnectivityStatement?: PatchedConnectivityStatement, options?: RawAxiosRequestConfig): AxiosPromise<ConnectivityStatement> {
            return localVarFp.composerConnectivityStatementAssignOwnerPartialUpdate(id, patchedConnectivityStatement, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns available users for assignment and possible state transitions for the selected items.
         * @param {Array<number>} [destinations] 
         * @param {Array<number>} [excludeIds] Multiple values may be separated by commas.
         * @param {number} [excludeSentenceId] 
         * @param {boolean} [hasStatementBeenExported] Is Exported
         * @param {Array<number>} [include] Multiple values may be separated by commas.
         * @param {string} [knowledgeStatement] 
         * @param {boolean} [notes] Checks if entity has notes
         * @param {Array<ComposerConnectivityStatementAvailableOptionsRetrieveOrderingEnum>} [ordering] Ordering
         * @param {Array<number>} [origins] 
         * @param {Array<number>} [populationset] 
         * @param {number} [sentenceId] 
         * @param {Array<ComposerConnectivityStatementAvailableOptionsRetrieveStateEnum>} [state] 
         * @param {Array<number>} [tags] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        composerConnectivityStatementAvailableOptionsRetrieve(destinations?: Array<number>, excludeIds?: Array<number>, excludeSentenceId?: number, hasStatementBeenExported?: boolean, include?: Array<number>, knowledgeStatement?: string, notes?: boolean, ordering?: Array<ComposerConnectivityStatementAvailableOptionsRetrieveOrderingEnum>, origins?: Array<number>, populationset?: Array<number>, sentenceId?: number, state?: Array<ComposerConnectivityStatementAvailableOptionsRetrieveStateEnum>, tags?: Array<number>, options?: RawAxiosRequestConfig): AxiosPromise<ConnectivityStatement> {
            return localVarFp.composerConnectivityStatementAvailableOptionsRetrieve(destinations, excludeIds, excludeSentenceId, hasStatementBeenExported, include, knowledgeStatement, notes, ordering, origins, populationset, sentenceId, state, tags, options).then((request) => request(axios, basePath));
        },
        /**
         * Apply a bulk action to the selected items and return the number of items updated successfully.
         * @param {Array<number>} [destinations] 
         * @param {Array<number>} [excludeIds] Multiple values may be separated by commas.
         * @param {number} [excludeSentenceId] 
         * @param {boolean} [hasStatementBeenExported] Is Exported
         * @param {Array<number>} [include] Multiple values may be separated by commas.
         * @param {string} [knowledgeStatement] 
         * @param {boolean} [notes] Checks if entity has notes
         * @param {Array<ComposerConnectivityStatementBulkActionCreateOrderingEnum>} [ordering] Ordering
         * @param {Array<number>} [origins] 
         * @param {Array<number>} [populationset] 
         * @param {number} [sentenceId] 
         * @param {Array<ComposerConnectivityStatementBulkActionCreateStateEnum>} [state] 
         * @param {Array<number>} [tags] 
         * @param {BulkAction} [bulkAction] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        composerConnectivityStatementBulkActionCreate(destinations?: Array<number>, excludeIds?: Array<number>, excludeSentenceId?: number, hasStatementBeenExported?: boolean, include?: Array<number>, knowledgeStatement?: string, notes?: boolean, ordering?: Array<ComposerConnectivityStatementBulkActionCreateOrderingEnum>, origins?: Array<number>, populationset?: Array<number>, sentenceId?: number, state?: Array<ComposerConnectivityStatementBulkActionCreateStateEnum>, tags?: Array<number>, bulkAction?: BulkAction, options?: RawAxiosRequestConfig): AxiosPromise<BulkActionResponse> {
            return localVarFp.composerConnectivityStatementBulkActionCreate(destinations, excludeIds, excludeSentenceId, hasStatementBeenExported, include, knowledgeStatement, notes, ordering, origins, populationset, sentenceId, state, tags, bulkAction, options).then((request) => request(axios, basePath));
        },
        /**
         * ConnectivityStatement
         * @param {number} id A unique integer value identifying this connectivity statement.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        composerConnectivityStatementCloneStatementRetrieve(id: number, options?: RawAxiosRequestConfig): AxiosPromise<ConnectivityStatement> {
            return localVarFp.composerConnectivityStatementCloneStatementRetrieve(id, options).then((request) => request(axios, basePath));
        },
        /**
         * ConnectivityStatement
         * @param {ConnectivityStatement} [connectivityStatement] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        composerConnectivityStatementCreate(connectivityStatement?: ConnectivityStatement, options?: RawAxiosRequestConfig): AxiosPromise<ConnectivityStatement> {
            return localVarFp.composerConnectivityStatementCreate(connectivityStatement, options).then((request) => request(axios, basePath));
        },
        /**
         * ConnectivityStatement
         * @param {number} id A unique integer value identifying this connectivity statement.
         * @param {number} provenanceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        composerConnectivityStatementDelProvenanceDestroy(id: number, provenanceId: number, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.composerConnectivityStatementDelProvenanceDestroy(id, provenanceId, options).then((request) => request(axios, basePath));
        },
        /**
         * ConnectivityStatement
         * @param {number} id A unique integer value identifying this connectivity statement.
         * @param {number} specieId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        composerConnectivityStatementDelSpecieCreate(id: number, specieId: number, options?: RawAxiosRequestConfig): AxiosPromise<ConnectivityStatement> {
            return localVarFp.composerConnectivityStatementDelSpecieCreate(id, specieId, options).then((request) => request(axios, basePath));
        },
        /**
         * ConnectivityStatement
         * @param {number} id A unique integer value identifying this connectivity statement.
         * @param {number} tagId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        composerConnectivityStatementDelTagCreate(id: number, tagId: number, options?: RawAxiosRequestConfig): AxiosPromise<ConnectivityStatement> {
            return localVarFp.composerConnectivityStatementDelTagCreate(id, tagId, options).then((request) => request(axios, basePath));
        },
        /**
         * ConnectivityStatement
         * @param {number} id A unique integer value identifying this connectivity statement.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        composerConnectivityStatementDestroy(id: number, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.composerConnectivityStatementDestroy(id, options).then((request) => request(axios, basePath));
        },
        /**
         * ConnectivityStatement
         * @param {number} id A unique integer value identifying this connectivity statement.
         * @param {string} transition 
         * @param {ConnectivityStatement} [connectivityStatement] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        composerConnectivityStatementDoTransitionCreate(id: number, transition: string, connectivityStatement?: ConnectivityStatement, options?: RawAxiosRequestConfig): AxiosPromise<ConnectivityStatement> {
            return localVarFp.composerConnectivityStatementDoTransitionCreate(id, transition, connectivityStatement, options).then((request) => request(axios, basePath));
        },
        /**
         * ConnectivityStatement
         * @param {Array<number>} [destinations] 
         * @param {Array<number>} [excludeIds] Multiple values may be separated by commas.
         * @param {number} [excludeSentenceId] 
         * @param {boolean} [hasStatementBeenExported] Is Exported
         * @param {Array<number>} [include] Multiple values may be separated by commas.
         * @param {string} [knowledgeStatement] 
         * @param {number} [limit] Number of results to return per page.
         * @param {boolean} [notes] Checks if entity has notes
         * @param {number} [offset] The initial index from which to return the results.
         * @param {Array<ComposerConnectivityStatementListOrderingEnum>} [ordering] Ordering
         * @param {Array<number>} [origins] 
         * @param {Array<number>} [populationset] 
         * @param {number} [sentenceId] 
         * @param {Array<ComposerConnectivityStatementListStateEnum>} [state] 
         * @param {Array<number>} [tags] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        composerConnectivityStatementList(destinations?: Array<number>, excludeIds?: Array<number>, excludeSentenceId?: number, hasStatementBeenExported?: boolean, include?: Array<number>, knowledgeStatement?: string, limit?: number, notes?: boolean, offset?: number, ordering?: Array<ComposerConnectivityStatementListOrderingEnum>, origins?: Array<number>, populationset?: Array<number>, sentenceId?: number, state?: Array<ComposerConnectivityStatementListStateEnum>, tags?: Array<number>, options?: RawAxiosRequestConfig): AxiosPromise<PaginatedBaseConnectivityStatementList> {
            return localVarFp.composerConnectivityStatementList(destinations, excludeIds, excludeSentenceId, hasStatementBeenExported, include, knowledgeStatement, limit, notes, offset, ordering, origins, populationset, sentenceId, state, tags, options).then((request) => request(axios, basePath));
        },
        /**
         * ConnectivityStatement
         * @param {number} id A unique integer value identifying this connectivity statement.
         * @param {PatchedConnectivityStatementUpdate} [patchedConnectivityStatementUpdate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        composerConnectivityStatementPartialUpdate(id: number, patchedConnectivityStatementUpdate?: PatchedConnectivityStatementUpdate, options?: RawAxiosRequestConfig): AxiosPromise<ConnectivityStatement> {
            return localVarFp.composerConnectivityStatementPartialUpdate(id, patchedConnectivityStatementUpdate, options).then((request) => request(axios, basePath));
        },
        /**
         * ConnectivityStatement
         * @param {number} id A unique integer value identifying this connectivity statement.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        composerConnectivityStatementRetrieve(id: number, options?: RawAxiosRequestConfig): AxiosPromise<ConnectivityStatement> {
            return localVarFp.composerConnectivityStatementRetrieve(id, options).then((request) => request(axios, basePath));
        },
        /**
         * ConnectivityStatement
         * @param {number} id A unique integer value identifying this connectivity statement.
         * @param {ConnectivityStatementUpdate} connectivityStatementUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        composerConnectivityStatementUpdate(id: number, connectivityStatementUpdate: ConnectivityStatementUpdate, options?: RawAxiosRequestConfig): AxiosPromise<ConnectivityStatement> {
            return localVarFp.composerConnectivityStatementUpdate(id, connectivityStatementUpdate, options).then((request) => request(axios, basePath));
        },
        /**
         * Destination
         * @param {Destination} destination 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        composerDestinationCreate(destination: Destination, options?: RawAxiosRequestConfig): AxiosPromise<Destination> {
            return localVarFp.composerDestinationCreate(destination, options).then((request) => request(axios, basePath));
        },
        /**
         * Destination
         * @param {number} id A unique integer value identifying this destination.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        composerDestinationDestroy(id: number, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.composerDestinationDestroy(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Destination
         * @param {number} [connectivityStatementId] 
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        composerDestinationList(connectivityStatementId?: number, limit?: number, offset?: number, options?: RawAxiosRequestConfig): AxiosPromise<PaginatedDestinationList> {
            return localVarFp.composerDestinationList(connectivityStatementId, limit, offset, options).then((request) => request(axios, basePath));
        },
        /**
         * Destination
         * @param {number} id A unique integer value identifying this destination.
         * @param {PatchedDestination} [patchedDestination] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        composerDestinationPartialUpdate(id: number, patchedDestination?: PatchedDestination, options?: RawAxiosRequestConfig): AxiosPromise<Destination> {
            return localVarFp.composerDestinationPartialUpdate(id, patchedDestination, options).then((request) => request(axios, basePath));
        },
        /**
         * Destination
         * @param {number} id A unique integer value identifying this destination.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        composerDestinationRetrieve(id: number, options?: RawAxiosRequestConfig): AxiosPromise<Destination> {
            return localVarFp.composerDestinationRetrieve(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Destination
         * @param {number} id A unique integer value identifying this destination.
         * @param {Destination} destination 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        composerDestinationUpdate(id: number, destination: Destination, options?: RawAxiosRequestConfig): AxiosPromise<Destination> {
            return localVarFp.composerDestinationUpdate(id, destination, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        composerJsonschemasRetrieve(options?: RawAxiosRequestConfig): AxiosPromise<{ [key: string]: any; }> {
            return localVarFp.composerJsonschemasRetrieve(options).then((request) => request(axios, basePath));
        },
        /**
         * Note
         * @param {Note} note 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        composerNoteCreate(note: Note, options?: RawAxiosRequestConfig): AxiosPromise<Note> {
            return localVarFp.composerNoteCreate(note, options).then((request) => request(axios, basePath));
        },
        /**
         * Note
         * @param {number} id A unique integer value identifying this note.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        composerNoteDestroy(id: number, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.composerNoteDestroy(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Note
         * @param {number | null} [connectivityStatementId] 
         * @param {boolean} [includeSystemNotes] Include System Notes
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {number | null} [sentenceId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        composerNoteList(connectivityStatementId?: number | null, includeSystemNotes?: boolean, limit?: number, offset?: number, sentenceId?: number | null, options?: RawAxiosRequestConfig): AxiosPromise<PaginatedNoteList> {
            return localVarFp.composerNoteList(connectivityStatementId, includeSystemNotes, limit, offset, sentenceId, options).then((request) => request(axios, basePath));
        },
        /**
         * Note
         * @param {number} id A unique integer value identifying this note.
         * @param {PatchedNote} [patchedNote] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        composerNotePartialUpdate(id: number, patchedNote?: PatchedNote, options?: RawAxiosRequestConfig): AxiosPromise<Note> {
            return localVarFp.composerNotePartialUpdate(id, patchedNote, options).then((request) => request(axios, basePath));
        },
        /**
         * Note
         * @param {number} id A unique integer value identifying this note.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        composerNoteRetrieve(id: number, options?: RawAxiosRequestConfig): AxiosPromise<Note> {
            return localVarFp.composerNoteRetrieve(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Tag
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        composerNoteTagList(limit?: number, offset?: number, options?: RawAxiosRequestConfig): AxiosPromise<PaginatedTagList> {
            return localVarFp.composerNoteTagList(limit, offset, options).then((request) => request(axios, basePath));
        },
        /**
         * Tag
         * @param {number} id A unique integer value identifying this tag.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        composerNoteTagRetrieve(id: number, options?: RawAxiosRequestConfig): AxiosPromise<Tag> {
            return localVarFp.composerNoteTagRetrieve(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Note
         * @param {number} id A unique integer value identifying this note.
         * @param {Note} note 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        composerNoteUpdate(id: number, note: Note, options?: RawAxiosRequestConfig): AxiosPromise<Note> {
            return localVarFp.composerNoteUpdate(id, note, options).then((request) => request(axios, basePath));
        },
        /**
         * Phenotype
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        composerPhenotypeList(limit?: number, offset?: number, options?: RawAxiosRequestConfig): AxiosPromise<PaginatedPhenotypeList> {
            return localVarFp.composerPhenotypeList(limit, offset, options).then((request) => request(axios, basePath));
        },
        /**
         * Phenotype
         * @param {number} id A unique integer value identifying this phenotype.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        composerPhenotypeRetrieve(id: number, options?: RawAxiosRequestConfig): AxiosPromise<Phenotype> {
            return localVarFp.composerPhenotypeRetrieve(id, options).then((request) => request(axios, basePath));
        },
        /**
         * PopulationSet
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        composerPopulationList(limit?: number, offset?: number, options?: RawAxiosRequestConfig): AxiosPromise<PaginatedPopulationSetList> {
            return localVarFp.composerPopulationList(limit, offset, options).then((request) => request(axios, basePath));
        },
        /**
         * PopulationSet
         * @param {number} id A unique integer value identifying this population set.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        composerPopulationRetrieve(id: number, options?: RawAxiosRequestConfig): AxiosPromise<PopulationSet> {
            return localVarFp.composerPopulationRetrieve(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Profile
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        composerProfileMyRetrieve(options?: RawAxiosRequestConfig): AxiosPromise<Profile> {
            return localVarFp.composerProfileMyRetrieve(options).then((request) => request(axios, basePath));
        },
        /**
         * Projection Phenotype
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        composerProjectionList(limit?: number, offset?: number, options?: RawAxiosRequestConfig): AxiosPromise<PaginatedProjectionPhenotypeList> {
            return localVarFp.composerProjectionList(limit, offset, options).then((request) => request(axios, basePath));
        },
        /**
         * Projection Phenotype
         * @param {number} id A unique integer value identifying this projection phenotype.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        composerProjectionRetrieve(id: number, options?: RawAxiosRequestConfig): AxiosPromise<ProjectionPhenotype> {
            return localVarFp.composerProjectionRetrieve(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Sentence
         * @param {number} id A unique integer value identifying this sentence.
         * @param {number} tagId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        composerSentenceAddTagCreate(id: number, tagId: number, options?: RawAxiosRequestConfig): AxiosPromise<Sentence> {
            return localVarFp.composerSentenceAddTagCreate(id, tagId, options).then((request) => request(axios, basePath));
        },
        /**
         * Sentence
         * @param {number} id A unique integer value identifying this sentence.
         * @param {PatchedSentence} [patchedSentence] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        composerSentenceAssignOwnerPartialUpdate(id: number, patchedSentence?: PatchedSentence, options?: RawAxiosRequestConfig): AxiosPromise<Sentence> {
            return localVarFp.composerSentenceAssignOwnerPartialUpdate(id, patchedSentence, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns available users for assignment and possible state transitions for the selected items.
         * @param {Array<string>} [batchName] Multiple values may be separated by commas.
         * @param {Array<string>} [exclude] Multiple values may be separated by commas.
         * @param {Array<number>} [include] Multiple values may be separated by commas.
         * @param {boolean} [notes] Checks if entity has notes
         * @param {Array<ComposerSentenceAvailableOptionsRetrieveOrderingEnum>} [ordering] Ordering
         * @param {Array<ComposerSentenceAvailableOptionsRetrieveStateEnum>} [state] 
         * @param {Array<number>} [tags] 
         * @param {string} [title] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        composerSentenceAvailableOptionsRetrieve(batchName?: Array<string>, exclude?: Array<string>, include?: Array<number>, notes?: boolean, ordering?: Array<ComposerSentenceAvailableOptionsRetrieveOrderingEnum>, state?: Array<ComposerSentenceAvailableOptionsRetrieveStateEnum>, tags?: Array<number>, title?: string, options?: RawAxiosRequestConfig): AxiosPromise<Sentence> {
            return localVarFp.composerSentenceAvailableOptionsRetrieve(batchName, exclude, include, notes, ordering, state, tags, title, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns a list of all unique batch names in the database.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        composerSentenceBatchNamesRetrieve(options?: RawAxiosRequestConfig): AxiosPromise<Sentence> {
            return localVarFp.composerSentenceBatchNamesRetrieve(options).then((request) => request(axios, basePath));
        },
        /**
         * Apply a bulk action to the selected items and return the number of items updated successfully.
         * @param {Array<string>} [batchName] Multiple values may be separated by commas.
         * @param {Array<string>} [exclude] Multiple values may be separated by commas.
         * @param {Array<number>} [include] Multiple values may be separated by commas.
         * @param {boolean} [notes] Checks if entity has notes
         * @param {Array<ComposerSentenceBulkActionCreateOrderingEnum>} [ordering] Ordering
         * @param {Array<ComposerSentenceBulkActionCreateStateEnum>} [state] 
         * @param {Array<number>} [tags] 
         * @param {string} [title] 
         * @param {BulkAction} [bulkAction] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        composerSentenceBulkActionCreate(batchName?: Array<string>, exclude?: Array<string>, include?: Array<number>, notes?: boolean, ordering?: Array<ComposerSentenceBulkActionCreateOrderingEnum>, state?: Array<ComposerSentenceBulkActionCreateStateEnum>, tags?: Array<number>, title?: string, bulkAction?: BulkAction, options?: RawAxiosRequestConfig): AxiosPromise<BulkActionResponse> {
            return localVarFp.composerSentenceBulkActionCreate(batchName, exclude, include, notes, ordering, state, tags, title, bulkAction, options).then((request) => request(axios, basePath));
        },
        /**
         * Sentence
         * @param {Sentence} sentence 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        composerSentenceCreate(sentence: Sentence, options?: RawAxiosRequestConfig): AxiosPromise<Sentence> {
            return localVarFp.composerSentenceCreate(sentence, options).then((request) => request(axios, basePath));
        },
        /**
         * Sentence
         * @param {number} id A unique integer value identifying this sentence.
         * @param {number} tagId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        composerSentenceDelTagCreate(id: number, tagId: number, options?: RawAxiosRequestConfig): AxiosPromise<Sentence> {
            return localVarFp.composerSentenceDelTagCreate(id, tagId, options).then((request) => request(axios, basePath));
        },
        /**
         * Sentence
         * @param {number} id A unique integer value identifying this sentence.
         * @param {string} transition 
         * @param {Sentence} sentence 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        composerSentenceDoTransitionCreate(id: number, transition: string, sentence: Sentence, options?: RawAxiosRequestConfig): AxiosPromise<Sentence> {
            return localVarFp.composerSentenceDoTransitionCreate(id, transition, sentence, options).then((request) => request(axios, basePath));
        },
        /**
         * Sentence
         * @param {Array<string>} [batchName] Multiple values may be separated by commas.
         * @param {Array<string>} [exclude] Multiple values may be separated by commas.
         * @param {Array<number>} [include] Multiple values may be separated by commas.
         * @param {number} [limit] Number of results to return per page.
         * @param {boolean} [notes] Checks if entity has notes
         * @param {number} [offset] The initial index from which to return the results.
         * @param {Array<ComposerSentenceListOrderingEnum>} [ordering] Ordering
         * @param {Array<ComposerSentenceListStateEnum>} [state] 
         * @param {Array<number>} [tags] 
         * @param {string} [title] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        composerSentenceList(batchName?: Array<string>, exclude?: Array<string>, include?: Array<number>, limit?: number, notes?: boolean, offset?: number, ordering?: Array<ComposerSentenceListOrderingEnum>, state?: Array<ComposerSentenceListStateEnum>, tags?: Array<number>, title?: string, options?: RawAxiosRequestConfig): AxiosPromise<PaginatedSentenceList> {
            return localVarFp.composerSentenceList(batchName, exclude, include, limit, notes, offset, ordering, state, tags, title, options).then((request) => request(axios, basePath));
        },
        /**
         * Sentence
         * @param {number} id A unique integer value identifying this sentence.
         * @param {PatchedSentence} [patchedSentence] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        composerSentencePartialUpdate(id: number, patchedSentence?: PatchedSentence, options?: RawAxiosRequestConfig): AxiosPromise<Sentence> {
            return localVarFp.composerSentencePartialUpdate(id, patchedSentence, options).then((request) => request(axios, basePath));
        },
        /**
         * Sentence
         * @param {number} id A unique integer value identifying this sentence.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        composerSentenceRetrieve(id: number, options?: RawAxiosRequestConfig): AxiosPromise<Sentence> {
            return localVarFp.composerSentenceRetrieve(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Sentence
         * @param {number} id A unique integer value identifying this sentence.
         * @param {Sentence} sentence 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        composerSentenceUpdate(id: number, sentence: Sentence, options?: RawAxiosRequestConfig): AxiosPromise<Sentence> {
            return localVarFp.composerSentenceUpdate(id, sentence, options).then((request) => request(axios, basePath));
        },
        /**
         * Sex
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        composerSexList(limit?: number, offset?: number, options?: RawAxiosRequestConfig): AxiosPromise<PaginatedSexList> {
            return localVarFp.composerSexList(limit, offset, options).then((request) => request(axios, basePath));
        },
        /**
         * Sex
         * @param {number} id A unique integer value identifying this sex.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        composerSexRetrieve(id: number, options?: RawAxiosRequestConfig): AxiosPromise<Sex> {
            return localVarFp.composerSexRetrieve(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Specie
         * @param {number} [limit] Number of results to return per page.
         * @param {string} [name] 
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        composerSpecieList(limit?: number, name?: string, offset?: number, options?: RawAxiosRequestConfig): AxiosPromise<PaginatedSpecieList> {
            return localVarFp.composerSpecieList(limit, name, offset, options).then((request) => request(axios, basePath));
        },
        /**
         * Specie
         * @param {number} id A unique integer value identifying this specie.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        composerSpecieRetrieve(id: number, options?: RawAxiosRequestConfig): AxiosPromise<Specie> {
            return localVarFp.composerSpecieRetrieve(id, options).then((request) => request(axios, basePath));
        },
        /**
         * StatementAlert
         * @param {StatementAlert} statementAlert 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        composerStatementAlertCreate(statementAlert: StatementAlert, options?: RawAxiosRequestConfig): AxiosPromise<StatementAlert> {
            return localVarFp.composerStatementAlertCreate(statementAlert, options).then((request) => request(axios, basePath));
        },
        /**
         * StatementAlert
         * @param {number} id A unique integer value identifying this statement alert.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        composerStatementAlertDestroy(id: number, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.composerStatementAlertDestroy(id, options).then((request) => request(axios, basePath));
        },
        /**
         * StatementAlert
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        composerStatementAlertList(limit?: number, offset?: number, options?: RawAxiosRequestConfig): AxiosPromise<PaginatedStatementAlertList> {
            return localVarFp.composerStatementAlertList(limit, offset, options).then((request) => request(axios, basePath));
        },
        /**
         * StatementAlert
         * @param {number} id A unique integer value identifying this statement alert.
         * @param {PatchedStatementAlert} [patchedStatementAlert] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        composerStatementAlertPartialUpdate(id: number, patchedStatementAlert?: PatchedStatementAlert, options?: RawAxiosRequestConfig): AxiosPromise<StatementAlert> {
            return localVarFp.composerStatementAlertPartialUpdate(id, patchedStatementAlert, options).then((request) => request(axios, basePath));
        },
        /**
         * StatementAlert
         * @param {number} id A unique integer value identifying this statement alert.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        composerStatementAlertRetrieve(id: number, options?: RawAxiosRequestConfig): AxiosPromise<StatementAlert> {
            return localVarFp.composerStatementAlertRetrieve(id, options).then((request) => request(axios, basePath));
        },
        /**
         * StatementAlert
         * @param {number} id A unique integer value identifying this statement alert.
         * @param {StatementAlert} statementAlert 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        composerStatementAlertUpdate(id: number, statementAlert: StatementAlert, options?: RawAxiosRequestConfig): AxiosPromise<StatementAlert> {
            return localVarFp.composerStatementAlertUpdate(id, statementAlert, options).then((request) => request(axios, basePath));
        },
        /**
         * Tag
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        composerTagList(limit?: number, offset?: number, options?: RawAxiosRequestConfig): AxiosPromise<PaginatedTagList> {
            return localVarFp.composerTagList(limit, offset, options).then((request) => request(axios, basePath));
        },
        /**
         * Tag
         * @param {number} id A unique integer value identifying this tag.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        composerTagRetrieve(id: number, options?: RawAxiosRequestConfig): AxiosPromise<Tag> {
            return localVarFp.composerTagRetrieve(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Via
         * @param {Via} via 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        composerViaCreate(via: Via, options?: RawAxiosRequestConfig): AxiosPromise<Via> {
            return localVarFp.composerViaCreate(via, options).then((request) => request(axios, basePath));
        },
        /**
         * Via
         * @param {number} id A unique integer value identifying this via.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        composerViaDestroy(id: number, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.composerViaDestroy(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Via
         * @param {number} [connectivityStatementId] 
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        composerViaList(connectivityStatementId?: number, limit?: number, offset?: number, options?: RawAxiosRequestConfig): AxiosPromise<PaginatedViaList> {
            return localVarFp.composerViaList(connectivityStatementId, limit, offset, options).then((request) => request(axios, basePath));
        },
        /**
         * Via
         * @param {number} id A unique integer value identifying this via.
         * @param {PatchedVia} [patchedVia] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        composerViaPartialUpdate(id: number, patchedVia?: PatchedVia, options?: RawAxiosRequestConfig): AxiosPromise<Via> {
            return localVarFp.composerViaPartialUpdate(id, patchedVia, options).then((request) => request(axios, basePath));
        },
        /**
         * Via
         * @param {number} id A unique integer value identifying this via.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        composerViaRetrieve(id: number, options?: RawAxiosRequestConfig): AxiosPromise<Via> {
            return localVarFp.composerViaRetrieve(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Via
         * @param {number} id A unique integer value identifying this via.
         * @param {Via} via 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        composerViaUpdate(id: number, via: Via, options?: RawAxiosRequestConfig): AxiosPromise<Via> {
            return localVarFp.composerViaUpdate(id, via, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ComposerApi - object-oriented interface
 * @export
 * @class ComposerApi
 * @extends {BaseAPI}
 */
export class ComposerApi extends BaseAPI {
    /**
     * A viewset for viewing the list of alert types.
     * @param {number} [limit] Number of results to return per page.
     * @param {number} [offset] The initial index from which to return the results.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComposerApi
     */
    public composerAlertList(limit?: number, offset?: number, options?: RawAxiosRequestConfig) {
        return ComposerApiFp(this.configuration).composerAlertList(limit, offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * A viewset for viewing the list of alert types.
     * @param {number} id A unique integer value identifying this alert type.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComposerApi
     */
    public composerAlertRetrieve(id: number, options?: RawAxiosRequestConfig) {
        return ComposerApiFp(this.configuration).composerAlertRetrieve(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * AnatomicalEntity
     * @param {Array<number>} [excludeIds] Multiple values may be separated by commas.
     * @param {number} [limit] Number of results to return per page.
     * @param {string} [name] 
     * @param {number} [offset] The initial index from which to return the results.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComposerApi
     */
    public composerAnatomicalEntityList(excludeIds?: Array<number>, limit?: number, name?: string, offset?: number, options?: RawAxiosRequestConfig) {
        return ComposerApiFp(this.configuration).composerAnatomicalEntityList(excludeIds, limit, name, offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * AnatomicalEntity
     * @param {number} id A unique integer value identifying this Anatomical Entity.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComposerApi
     */
    public composerAnatomicalEntityRetrieve(id: number, options?: RawAxiosRequestConfig) {
        return ComposerApiFp(this.configuration).composerAnatomicalEntityRetrieve(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ConnectivityStatement
     * @param {number} id A unique integer value identifying this connectivity statement.
     * @param {string} uri 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComposerApi
     */
    public composerConnectivityStatementAddProvenanceCreate(id: number, uri: string, options?: RawAxiosRequestConfig) {
        return ComposerApiFp(this.configuration).composerConnectivityStatementAddProvenanceCreate(id, uri, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ConnectivityStatement
     * @param {number} id A unique integer value identifying this connectivity statement.
     * @param {number} specieId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComposerApi
     */
    public composerConnectivityStatementAddSpecieCreate(id: number, specieId: number, options?: RawAxiosRequestConfig) {
        return ComposerApiFp(this.configuration).composerConnectivityStatementAddSpecieCreate(id, specieId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ConnectivityStatement
     * @param {number} id A unique integer value identifying this connectivity statement.
     * @param {number} tagId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComposerApi
     */
    public composerConnectivityStatementAddTagCreate(id: number, tagId: number, options?: RawAxiosRequestConfig) {
        return ComposerApiFp(this.configuration).composerConnectivityStatementAddTagCreate(id, tagId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ConnectivityStatement
     * @param {number} id A unique integer value identifying this connectivity statement.
     * @param {PatchedConnectivityStatement} [patchedConnectivityStatement] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComposerApi
     */
    public composerConnectivityStatementAssignOwnerPartialUpdate(id: number, patchedConnectivityStatement?: PatchedConnectivityStatement, options?: RawAxiosRequestConfig) {
        return ComposerApiFp(this.configuration).composerConnectivityStatementAssignOwnerPartialUpdate(id, patchedConnectivityStatement, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns available users for assignment and possible state transitions for the selected items.
     * @param {Array<number>} [destinations] 
     * @param {Array<number>} [excludeIds] Multiple values may be separated by commas.
     * @param {number} [excludeSentenceId] 
     * @param {boolean} [hasStatementBeenExported] Is Exported
     * @param {Array<number>} [include] Multiple values may be separated by commas.
     * @param {string} [knowledgeStatement] 
     * @param {boolean} [notes] Checks if entity has notes
     * @param {Array<ComposerConnectivityStatementAvailableOptionsRetrieveOrderingEnum>} [ordering] Ordering
     * @param {Array<number>} [origins] 
     * @param {Array<number>} [populationset] 
     * @param {number} [sentenceId] 
     * @param {Array<ComposerConnectivityStatementAvailableOptionsRetrieveStateEnum>} [state] 
     * @param {Array<number>} [tags] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComposerApi
     */
    public composerConnectivityStatementAvailableOptionsRetrieve(destinations?: Array<number>, excludeIds?: Array<number>, excludeSentenceId?: number, hasStatementBeenExported?: boolean, include?: Array<number>, knowledgeStatement?: string, notes?: boolean, ordering?: Array<ComposerConnectivityStatementAvailableOptionsRetrieveOrderingEnum>, origins?: Array<number>, populationset?: Array<number>, sentenceId?: number, state?: Array<ComposerConnectivityStatementAvailableOptionsRetrieveStateEnum>, tags?: Array<number>, options?: RawAxiosRequestConfig) {
        return ComposerApiFp(this.configuration).composerConnectivityStatementAvailableOptionsRetrieve(destinations, excludeIds, excludeSentenceId, hasStatementBeenExported, include, knowledgeStatement, notes, ordering, origins, populationset, sentenceId, state, tags, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Apply a bulk action to the selected items and return the number of items updated successfully.
     * @param {Array<number>} [destinations] 
     * @param {Array<number>} [excludeIds] Multiple values may be separated by commas.
     * @param {number} [excludeSentenceId] 
     * @param {boolean} [hasStatementBeenExported] Is Exported
     * @param {Array<number>} [include] Multiple values may be separated by commas.
     * @param {string} [knowledgeStatement] 
     * @param {boolean} [notes] Checks if entity has notes
     * @param {Array<ComposerConnectivityStatementBulkActionCreateOrderingEnum>} [ordering] Ordering
     * @param {Array<number>} [origins] 
     * @param {Array<number>} [populationset] 
     * @param {number} [sentenceId] 
     * @param {Array<ComposerConnectivityStatementBulkActionCreateStateEnum>} [state] 
     * @param {Array<number>} [tags] 
     * @param {BulkAction} [bulkAction] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComposerApi
     */
    public composerConnectivityStatementBulkActionCreate(destinations?: Array<number>, excludeIds?: Array<number>, excludeSentenceId?: number, hasStatementBeenExported?: boolean, include?: Array<number>, knowledgeStatement?: string, notes?: boolean, ordering?: Array<ComposerConnectivityStatementBulkActionCreateOrderingEnum>, origins?: Array<number>, populationset?: Array<number>, sentenceId?: number, state?: Array<ComposerConnectivityStatementBulkActionCreateStateEnum>, tags?: Array<number>, bulkAction?: BulkAction, options?: RawAxiosRequestConfig) {
        return ComposerApiFp(this.configuration).composerConnectivityStatementBulkActionCreate(destinations, excludeIds, excludeSentenceId, hasStatementBeenExported, include, knowledgeStatement, notes, ordering, origins, populationset, sentenceId, state, tags, bulkAction, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ConnectivityStatement
     * @param {number} id A unique integer value identifying this connectivity statement.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComposerApi
     */
    public composerConnectivityStatementCloneStatementRetrieve(id: number, options?: RawAxiosRequestConfig) {
        return ComposerApiFp(this.configuration).composerConnectivityStatementCloneStatementRetrieve(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ConnectivityStatement
     * @param {ConnectivityStatement} [connectivityStatement] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComposerApi
     */
    public composerConnectivityStatementCreate(connectivityStatement?: ConnectivityStatement, options?: RawAxiosRequestConfig) {
        return ComposerApiFp(this.configuration).composerConnectivityStatementCreate(connectivityStatement, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ConnectivityStatement
     * @param {number} id A unique integer value identifying this connectivity statement.
     * @param {number} provenanceId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComposerApi
     */
    public composerConnectivityStatementDelProvenanceDestroy(id: number, provenanceId: number, options?: RawAxiosRequestConfig) {
        return ComposerApiFp(this.configuration).composerConnectivityStatementDelProvenanceDestroy(id, provenanceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ConnectivityStatement
     * @param {number} id A unique integer value identifying this connectivity statement.
     * @param {number} specieId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComposerApi
     */
    public composerConnectivityStatementDelSpecieCreate(id: number, specieId: number, options?: RawAxiosRequestConfig) {
        return ComposerApiFp(this.configuration).composerConnectivityStatementDelSpecieCreate(id, specieId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ConnectivityStatement
     * @param {number} id A unique integer value identifying this connectivity statement.
     * @param {number} tagId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComposerApi
     */
    public composerConnectivityStatementDelTagCreate(id: number, tagId: number, options?: RawAxiosRequestConfig) {
        return ComposerApiFp(this.configuration).composerConnectivityStatementDelTagCreate(id, tagId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ConnectivityStatement
     * @param {number} id A unique integer value identifying this connectivity statement.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComposerApi
     */
    public composerConnectivityStatementDestroy(id: number, options?: RawAxiosRequestConfig) {
        return ComposerApiFp(this.configuration).composerConnectivityStatementDestroy(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ConnectivityStatement
     * @param {number} id A unique integer value identifying this connectivity statement.
     * @param {string} transition 
     * @param {ConnectivityStatement} [connectivityStatement] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComposerApi
     */
    public composerConnectivityStatementDoTransitionCreate(id: number, transition: string, connectivityStatement?: ConnectivityStatement, options?: RawAxiosRequestConfig) {
        return ComposerApiFp(this.configuration).composerConnectivityStatementDoTransitionCreate(id, transition, connectivityStatement, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ConnectivityStatement
     * @param {Array<number>} [destinations] 
     * @param {Array<number>} [excludeIds] Multiple values may be separated by commas.
     * @param {number} [excludeSentenceId] 
     * @param {boolean} [hasStatementBeenExported] Is Exported
     * @param {Array<number>} [include] Multiple values may be separated by commas.
     * @param {string} [knowledgeStatement] 
     * @param {number} [limit] Number of results to return per page.
     * @param {boolean} [notes] Checks if entity has notes
     * @param {number} [offset] The initial index from which to return the results.
     * @param {Array<ComposerConnectivityStatementListOrderingEnum>} [ordering] Ordering
     * @param {Array<number>} [origins] 
     * @param {Array<number>} [populationset] 
     * @param {number} [sentenceId] 
     * @param {Array<ComposerConnectivityStatementListStateEnum>} [state] 
     * @param {Array<number>} [tags] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComposerApi
     */
    public composerConnectivityStatementList(destinations?: Array<number>, excludeIds?: Array<number>, excludeSentenceId?: number, hasStatementBeenExported?: boolean, include?: Array<number>, knowledgeStatement?: string, limit?: number, notes?: boolean, offset?: number, ordering?: Array<ComposerConnectivityStatementListOrderingEnum>, origins?: Array<number>, populationset?: Array<number>, sentenceId?: number, state?: Array<ComposerConnectivityStatementListStateEnum>, tags?: Array<number>, options?: RawAxiosRequestConfig) {
        return ComposerApiFp(this.configuration).composerConnectivityStatementList(destinations, excludeIds, excludeSentenceId, hasStatementBeenExported, include, knowledgeStatement, limit, notes, offset, ordering, origins, populationset, sentenceId, state, tags, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ConnectivityStatement
     * @param {number} id A unique integer value identifying this connectivity statement.
     * @param {PatchedConnectivityStatementUpdate} [patchedConnectivityStatementUpdate] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComposerApi
     */
    public composerConnectivityStatementPartialUpdate(id: number, patchedConnectivityStatementUpdate?: PatchedConnectivityStatementUpdate, options?: RawAxiosRequestConfig) {
        return ComposerApiFp(this.configuration).composerConnectivityStatementPartialUpdate(id, patchedConnectivityStatementUpdate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ConnectivityStatement
     * @param {number} id A unique integer value identifying this connectivity statement.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComposerApi
     */
    public composerConnectivityStatementRetrieve(id: number, options?: RawAxiosRequestConfig) {
        return ComposerApiFp(this.configuration).composerConnectivityStatementRetrieve(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ConnectivityStatement
     * @param {number} id A unique integer value identifying this connectivity statement.
     * @param {ConnectivityStatementUpdate} connectivityStatementUpdate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComposerApi
     */
    public composerConnectivityStatementUpdate(id: number, connectivityStatementUpdate: ConnectivityStatementUpdate, options?: RawAxiosRequestConfig) {
        return ComposerApiFp(this.configuration).composerConnectivityStatementUpdate(id, connectivityStatementUpdate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Destination
     * @param {Destination} destination 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComposerApi
     */
    public composerDestinationCreate(destination: Destination, options?: RawAxiosRequestConfig) {
        return ComposerApiFp(this.configuration).composerDestinationCreate(destination, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Destination
     * @param {number} id A unique integer value identifying this destination.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComposerApi
     */
    public composerDestinationDestroy(id: number, options?: RawAxiosRequestConfig) {
        return ComposerApiFp(this.configuration).composerDestinationDestroy(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Destination
     * @param {number} [connectivityStatementId] 
     * @param {number} [limit] Number of results to return per page.
     * @param {number} [offset] The initial index from which to return the results.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComposerApi
     */
    public composerDestinationList(connectivityStatementId?: number, limit?: number, offset?: number, options?: RawAxiosRequestConfig) {
        return ComposerApiFp(this.configuration).composerDestinationList(connectivityStatementId, limit, offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Destination
     * @param {number} id A unique integer value identifying this destination.
     * @param {PatchedDestination} [patchedDestination] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComposerApi
     */
    public composerDestinationPartialUpdate(id: number, patchedDestination?: PatchedDestination, options?: RawAxiosRequestConfig) {
        return ComposerApiFp(this.configuration).composerDestinationPartialUpdate(id, patchedDestination, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Destination
     * @param {number} id A unique integer value identifying this destination.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComposerApi
     */
    public composerDestinationRetrieve(id: number, options?: RawAxiosRequestConfig) {
        return ComposerApiFp(this.configuration).composerDestinationRetrieve(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Destination
     * @param {number} id A unique integer value identifying this destination.
     * @param {Destination} destination 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComposerApi
     */
    public composerDestinationUpdate(id: number, destination: Destination, options?: RawAxiosRequestConfig) {
        return ComposerApiFp(this.configuration).composerDestinationUpdate(id, destination, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComposerApi
     */
    public composerJsonschemasRetrieve(options?: RawAxiosRequestConfig) {
        return ComposerApiFp(this.configuration).composerJsonschemasRetrieve(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Note
     * @param {Note} note 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComposerApi
     */
    public composerNoteCreate(note: Note, options?: RawAxiosRequestConfig) {
        return ComposerApiFp(this.configuration).composerNoteCreate(note, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Note
     * @param {number} id A unique integer value identifying this note.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComposerApi
     */
    public composerNoteDestroy(id: number, options?: RawAxiosRequestConfig) {
        return ComposerApiFp(this.configuration).composerNoteDestroy(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Note
     * @param {number | null} [connectivityStatementId] 
     * @param {boolean} [includeSystemNotes] Include System Notes
     * @param {number} [limit] Number of results to return per page.
     * @param {number} [offset] The initial index from which to return the results.
     * @param {number | null} [sentenceId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComposerApi
     */
    public composerNoteList(connectivityStatementId?: number | null, includeSystemNotes?: boolean, limit?: number, offset?: number, sentenceId?: number | null, options?: RawAxiosRequestConfig) {
        return ComposerApiFp(this.configuration).composerNoteList(connectivityStatementId, includeSystemNotes, limit, offset, sentenceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Note
     * @param {number} id A unique integer value identifying this note.
     * @param {PatchedNote} [patchedNote] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComposerApi
     */
    public composerNotePartialUpdate(id: number, patchedNote?: PatchedNote, options?: RawAxiosRequestConfig) {
        return ComposerApiFp(this.configuration).composerNotePartialUpdate(id, patchedNote, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Note
     * @param {number} id A unique integer value identifying this note.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComposerApi
     */
    public composerNoteRetrieve(id: number, options?: RawAxiosRequestConfig) {
        return ComposerApiFp(this.configuration).composerNoteRetrieve(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Tag
     * @param {number} [limit] Number of results to return per page.
     * @param {number} [offset] The initial index from which to return the results.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComposerApi
     */
    public composerNoteTagList(limit?: number, offset?: number, options?: RawAxiosRequestConfig) {
        return ComposerApiFp(this.configuration).composerNoteTagList(limit, offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Tag
     * @param {number} id A unique integer value identifying this tag.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComposerApi
     */
    public composerNoteTagRetrieve(id: number, options?: RawAxiosRequestConfig) {
        return ComposerApiFp(this.configuration).composerNoteTagRetrieve(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Note
     * @param {number} id A unique integer value identifying this note.
     * @param {Note} note 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComposerApi
     */
    public composerNoteUpdate(id: number, note: Note, options?: RawAxiosRequestConfig) {
        return ComposerApiFp(this.configuration).composerNoteUpdate(id, note, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Phenotype
     * @param {number} [limit] Number of results to return per page.
     * @param {number} [offset] The initial index from which to return the results.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComposerApi
     */
    public composerPhenotypeList(limit?: number, offset?: number, options?: RawAxiosRequestConfig) {
        return ComposerApiFp(this.configuration).composerPhenotypeList(limit, offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Phenotype
     * @param {number} id A unique integer value identifying this phenotype.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComposerApi
     */
    public composerPhenotypeRetrieve(id: number, options?: RawAxiosRequestConfig) {
        return ComposerApiFp(this.configuration).composerPhenotypeRetrieve(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * PopulationSet
     * @param {number} [limit] Number of results to return per page.
     * @param {number} [offset] The initial index from which to return the results.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComposerApi
     */
    public composerPopulationList(limit?: number, offset?: number, options?: RawAxiosRequestConfig) {
        return ComposerApiFp(this.configuration).composerPopulationList(limit, offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * PopulationSet
     * @param {number} id A unique integer value identifying this population set.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComposerApi
     */
    public composerPopulationRetrieve(id: number, options?: RawAxiosRequestConfig) {
        return ComposerApiFp(this.configuration).composerPopulationRetrieve(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Profile
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComposerApi
     */
    public composerProfileMyRetrieve(options?: RawAxiosRequestConfig) {
        return ComposerApiFp(this.configuration).composerProfileMyRetrieve(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Projection Phenotype
     * @param {number} [limit] Number of results to return per page.
     * @param {number} [offset] The initial index from which to return the results.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComposerApi
     */
    public composerProjectionList(limit?: number, offset?: number, options?: RawAxiosRequestConfig) {
        return ComposerApiFp(this.configuration).composerProjectionList(limit, offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Projection Phenotype
     * @param {number} id A unique integer value identifying this projection phenotype.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComposerApi
     */
    public composerProjectionRetrieve(id: number, options?: RawAxiosRequestConfig) {
        return ComposerApiFp(this.configuration).composerProjectionRetrieve(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Sentence
     * @param {number} id A unique integer value identifying this sentence.
     * @param {number} tagId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComposerApi
     */
    public composerSentenceAddTagCreate(id: number, tagId: number, options?: RawAxiosRequestConfig) {
        return ComposerApiFp(this.configuration).composerSentenceAddTagCreate(id, tagId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Sentence
     * @param {number} id A unique integer value identifying this sentence.
     * @param {PatchedSentence} [patchedSentence] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComposerApi
     */
    public composerSentenceAssignOwnerPartialUpdate(id: number, patchedSentence?: PatchedSentence, options?: RawAxiosRequestConfig) {
        return ComposerApiFp(this.configuration).composerSentenceAssignOwnerPartialUpdate(id, patchedSentence, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns available users for assignment and possible state transitions for the selected items.
     * @param {Array<string>} [batchName] Multiple values may be separated by commas.
     * @param {Array<string>} [exclude] Multiple values may be separated by commas.
     * @param {Array<number>} [include] Multiple values may be separated by commas.
     * @param {boolean} [notes] Checks if entity has notes
     * @param {Array<ComposerSentenceAvailableOptionsRetrieveOrderingEnum>} [ordering] Ordering
     * @param {Array<ComposerSentenceAvailableOptionsRetrieveStateEnum>} [state] 
     * @param {Array<number>} [tags] 
     * @param {string} [title] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComposerApi
     */
    public composerSentenceAvailableOptionsRetrieve(batchName?: Array<string>, exclude?: Array<string>, include?: Array<number>, notes?: boolean, ordering?: Array<ComposerSentenceAvailableOptionsRetrieveOrderingEnum>, state?: Array<ComposerSentenceAvailableOptionsRetrieveStateEnum>, tags?: Array<number>, title?: string, options?: RawAxiosRequestConfig) {
        return ComposerApiFp(this.configuration).composerSentenceAvailableOptionsRetrieve(batchName, exclude, include, notes, ordering, state, tags, title, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns a list of all unique batch names in the database.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComposerApi
     */
    public composerSentenceBatchNamesRetrieve(options?: RawAxiosRequestConfig) {
        return ComposerApiFp(this.configuration).composerSentenceBatchNamesRetrieve(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Apply a bulk action to the selected items and return the number of items updated successfully.
     * @param {Array<string>} [batchName] Multiple values may be separated by commas.
     * @param {Array<string>} [exclude] Multiple values may be separated by commas.
     * @param {Array<number>} [include] Multiple values may be separated by commas.
     * @param {boolean} [notes] Checks if entity has notes
     * @param {Array<ComposerSentenceBulkActionCreateOrderingEnum>} [ordering] Ordering
     * @param {Array<ComposerSentenceBulkActionCreateStateEnum>} [state] 
     * @param {Array<number>} [tags] 
     * @param {string} [title] 
     * @param {BulkAction} [bulkAction] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComposerApi
     */
    public composerSentenceBulkActionCreate(batchName?: Array<string>, exclude?: Array<string>, include?: Array<number>, notes?: boolean, ordering?: Array<ComposerSentenceBulkActionCreateOrderingEnum>, state?: Array<ComposerSentenceBulkActionCreateStateEnum>, tags?: Array<number>, title?: string, bulkAction?: BulkAction, options?: RawAxiosRequestConfig) {
        return ComposerApiFp(this.configuration).composerSentenceBulkActionCreate(batchName, exclude, include, notes, ordering, state, tags, title, bulkAction, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Sentence
     * @param {Sentence} sentence 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComposerApi
     */
    public composerSentenceCreate(sentence: Sentence, options?: RawAxiosRequestConfig) {
        return ComposerApiFp(this.configuration).composerSentenceCreate(sentence, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Sentence
     * @param {number} id A unique integer value identifying this sentence.
     * @param {number} tagId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComposerApi
     */
    public composerSentenceDelTagCreate(id: number, tagId: number, options?: RawAxiosRequestConfig) {
        return ComposerApiFp(this.configuration).composerSentenceDelTagCreate(id, tagId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Sentence
     * @param {number} id A unique integer value identifying this sentence.
     * @param {string} transition 
     * @param {Sentence} sentence 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComposerApi
     */
    public composerSentenceDoTransitionCreate(id: number, transition: string, sentence: Sentence, options?: RawAxiosRequestConfig) {
        return ComposerApiFp(this.configuration).composerSentenceDoTransitionCreate(id, transition, sentence, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Sentence
     * @param {Array<string>} [batchName] Multiple values may be separated by commas.
     * @param {Array<string>} [exclude] Multiple values may be separated by commas.
     * @param {Array<number>} [include] Multiple values may be separated by commas.
     * @param {number} [limit] Number of results to return per page.
     * @param {boolean} [notes] Checks if entity has notes
     * @param {number} [offset] The initial index from which to return the results.
     * @param {Array<ComposerSentenceListOrderingEnum>} [ordering] Ordering
     * @param {Array<ComposerSentenceListStateEnum>} [state] 
     * @param {Array<number>} [tags] 
     * @param {string} [title] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComposerApi
     */
    public composerSentenceList(batchName?: Array<string>, exclude?: Array<string>, include?: Array<number>, limit?: number, notes?: boolean, offset?: number, ordering?: Array<ComposerSentenceListOrderingEnum>, state?: Array<ComposerSentenceListStateEnum>, tags?: Array<number>, title?: string, options?: RawAxiosRequestConfig) {
        return ComposerApiFp(this.configuration).composerSentenceList(batchName, exclude, include, limit, notes, offset, ordering, state, tags, title, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Sentence
     * @param {number} id A unique integer value identifying this sentence.
     * @param {PatchedSentence} [patchedSentence] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComposerApi
     */
    public composerSentencePartialUpdate(id: number, patchedSentence?: PatchedSentence, options?: RawAxiosRequestConfig) {
        return ComposerApiFp(this.configuration).composerSentencePartialUpdate(id, patchedSentence, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Sentence
     * @param {number} id A unique integer value identifying this sentence.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComposerApi
     */
    public composerSentenceRetrieve(id: number, options?: RawAxiosRequestConfig) {
        return ComposerApiFp(this.configuration).composerSentenceRetrieve(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Sentence
     * @param {number} id A unique integer value identifying this sentence.
     * @param {Sentence} sentence 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComposerApi
     */
    public composerSentenceUpdate(id: number, sentence: Sentence, options?: RawAxiosRequestConfig) {
        return ComposerApiFp(this.configuration).composerSentenceUpdate(id, sentence, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Sex
     * @param {number} [limit] Number of results to return per page.
     * @param {number} [offset] The initial index from which to return the results.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComposerApi
     */
    public composerSexList(limit?: number, offset?: number, options?: RawAxiosRequestConfig) {
        return ComposerApiFp(this.configuration).composerSexList(limit, offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Sex
     * @param {number} id A unique integer value identifying this sex.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComposerApi
     */
    public composerSexRetrieve(id: number, options?: RawAxiosRequestConfig) {
        return ComposerApiFp(this.configuration).composerSexRetrieve(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Specie
     * @param {number} [limit] Number of results to return per page.
     * @param {string} [name] 
     * @param {number} [offset] The initial index from which to return the results.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComposerApi
     */
    public composerSpecieList(limit?: number, name?: string, offset?: number, options?: RawAxiosRequestConfig) {
        return ComposerApiFp(this.configuration).composerSpecieList(limit, name, offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Specie
     * @param {number} id A unique integer value identifying this specie.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComposerApi
     */
    public composerSpecieRetrieve(id: number, options?: RawAxiosRequestConfig) {
        return ComposerApiFp(this.configuration).composerSpecieRetrieve(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * StatementAlert
     * @param {StatementAlert} statementAlert 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComposerApi
     */
    public composerStatementAlertCreate(statementAlert: StatementAlert, options?: RawAxiosRequestConfig) {
        return ComposerApiFp(this.configuration).composerStatementAlertCreate(statementAlert, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * StatementAlert
     * @param {number} id A unique integer value identifying this statement alert.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComposerApi
     */
    public composerStatementAlertDestroy(id: number, options?: RawAxiosRequestConfig) {
        return ComposerApiFp(this.configuration).composerStatementAlertDestroy(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * StatementAlert
     * @param {number} [limit] Number of results to return per page.
     * @param {number} [offset] The initial index from which to return the results.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComposerApi
     */
    public composerStatementAlertList(limit?: number, offset?: number, options?: RawAxiosRequestConfig) {
        return ComposerApiFp(this.configuration).composerStatementAlertList(limit, offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * StatementAlert
     * @param {number} id A unique integer value identifying this statement alert.
     * @param {PatchedStatementAlert} [patchedStatementAlert] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComposerApi
     */
    public composerStatementAlertPartialUpdate(id: number, patchedStatementAlert?: PatchedStatementAlert, options?: RawAxiosRequestConfig) {
        return ComposerApiFp(this.configuration).composerStatementAlertPartialUpdate(id, patchedStatementAlert, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * StatementAlert
     * @param {number} id A unique integer value identifying this statement alert.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComposerApi
     */
    public composerStatementAlertRetrieve(id: number, options?: RawAxiosRequestConfig) {
        return ComposerApiFp(this.configuration).composerStatementAlertRetrieve(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * StatementAlert
     * @param {number} id A unique integer value identifying this statement alert.
     * @param {StatementAlert} statementAlert 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComposerApi
     */
    public composerStatementAlertUpdate(id: number, statementAlert: StatementAlert, options?: RawAxiosRequestConfig) {
        return ComposerApiFp(this.configuration).composerStatementAlertUpdate(id, statementAlert, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Tag
     * @param {number} [limit] Number of results to return per page.
     * @param {number} [offset] The initial index from which to return the results.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComposerApi
     */
    public composerTagList(limit?: number, offset?: number, options?: RawAxiosRequestConfig) {
        return ComposerApiFp(this.configuration).composerTagList(limit, offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Tag
     * @param {number} id A unique integer value identifying this tag.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComposerApi
     */
    public composerTagRetrieve(id: number, options?: RawAxiosRequestConfig) {
        return ComposerApiFp(this.configuration).composerTagRetrieve(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Via
     * @param {Via} via 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComposerApi
     */
    public composerViaCreate(via: Via, options?: RawAxiosRequestConfig) {
        return ComposerApiFp(this.configuration).composerViaCreate(via, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Via
     * @param {number} id A unique integer value identifying this via.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComposerApi
     */
    public composerViaDestroy(id: number, options?: RawAxiosRequestConfig) {
        return ComposerApiFp(this.configuration).composerViaDestroy(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Via
     * @param {number} [connectivityStatementId] 
     * @param {number} [limit] Number of results to return per page.
     * @param {number} [offset] The initial index from which to return the results.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComposerApi
     */
    public composerViaList(connectivityStatementId?: number, limit?: number, offset?: number, options?: RawAxiosRequestConfig) {
        return ComposerApiFp(this.configuration).composerViaList(connectivityStatementId, limit, offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Via
     * @param {number} id A unique integer value identifying this via.
     * @param {PatchedVia} [patchedVia] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComposerApi
     */
    public composerViaPartialUpdate(id: number, patchedVia?: PatchedVia, options?: RawAxiosRequestConfig) {
        return ComposerApiFp(this.configuration).composerViaPartialUpdate(id, patchedVia, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Via
     * @param {number} id A unique integer value identifying this via.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComposerApi
     */
    public composerViaRetrieve(id: number, options?: RawAxiosRequestConfig) {
        return ComposerApiFp(this.configuration).composerViaRetrieve(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Via
     * @param {number} id A unique integer value identifying this via.
     * @param {Via} via 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComposerApi
     */
    public composerViaUpdate(id: number, via: Via, options?: RawAxiosRequestConfig) {
        return ComposerApiFp(this.configuration).composerViaUpdate(id, via, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * @export
 */
export const ComposerConnectivityStatementAvailableOptionsRetrieveOrderingEnum = {
    Id: '-id',
    LastEdited: '-last_edited',
    Id2: 'id',
    LastEdited2: 'last_edited'
} as const;
export type ComposerConnectivityStatementAvailableOptionsRetrieveOrderingEnum = typeof ComposerConnectivityStatementAvailableOptionsRetrieveOrderingEnum[keyof typeof ComposerConnectivityStatementAvailableOptionsRetrieveOrderingEnum];
/**
 * @export
 */
export const ComposerConnectivityStatementAvailableOptionsRetrieveStateEnum = {
    ComposeNow: 'compose_now',
    Deprecated: 'deprecated',
    Draft: 'draft',
    Exported: 'exported',
    InProgress: 'in_progress',
    Invalid: 'invalid',
    NpoApproved: 'npo_approved',
    Rejected: 'rejected',
    Revise: 'revise',
    ToBeReviewed: 'to_be_reviewed'
} as const;
export type ComposerConnectivityStatementAvailableOptionsRetrieveStateEnum = typeof ComposerConnectivityStatementAvailableOptionsRetrieveStateEnum[keyof typeof ComposerConnectivityStatementAvailableOptionsRetrieveStateEnum];
/**
 * @export
 */
export const ComposerConnectivityStatementBulkActionCreateOrderingEnum = {
    Id: '-id',
    LastEdited: '-last_edited',
    Id2: 'id',
    LastEdited2: 'last_edited'
} as const;
export type ComposerConnectivityStatementBulkActionCreateOrderingEnum = typeof ComposerConnectivityStatementBulkActionCreateOrderingEnum[keyof typeof ComposerConnectivityStatementBulkActionCreateOrderingEnum];
/**
 * @export
 */
export const ComposerConnectivityStatementBulkActionCreateStateEnum = {
    ComposeNow: 'compose_now',
    Deprecated: 'deprecated',
    Draft: 'draft',
    Exported: 'exported',
    InProgress: 'in_progress',
    Invalid: 'invalid',
    NpoApproved: 'npo_approved',
    Rejected: 'rejected',
    Revise: 'revise',
    ToBeReviewed: 'to_be_reviewed'
} as const;
export type ComposerConnectivityStatementBulkActionCreateStateEnum = typeof ComposerConnectivityStatementBulkActionCreateStateEnum[keyof typeof ComposerConnectivityStatementBulkActionCreateStateEnum];
/**
 * @export
 */
export const ComposerConnectivityStatementListOrderingEnum = {
    Id: '-id',
    LastEdited: '-last_edited',
    Id2: 'id',
    LastEdited2: 'last_edited'
} as const;
export type ComposerConnectivityStatementListOrderingEnum = typeof ComposerConnectivityStatementListOrderingEnum[keyof typeof ComposerConnectivityStatementListOrderingEnum];
/**
 * @export
 */
export const ComposerConnectivityStatementListStateEnum = {
    ComposeNow: 'compose_now',
    Deprecated: 'deprecated',
    Draft: 'draft',
    Exported: 'exported',
    InProgress: 'in_progress',
    Invalid: 'invalid',
    NpoApproved: 'npo_approved',
    Rejected: 'rejected',
    Revise: 'revise',
    ToBeReviewed: 'to_be_reviewed'
} as const;
export type ComposerConnectivityStatementListStateEnum = typeof ComposerConnectivityStatementListStateEnum[keyof typeof ComposerConnectivityStatementListStateEnum];
/**
 * @export
 */
export const ComposerSentenceAvailableOptionsRetrieveOrderingEnum = {
    Id: '-id',
    LastEdited: '-last_edited',
    Owner: '-owner',
    Id2: 'id',
    LastEdited2: 'last_edited',
    Owner2: 'owner'
} as const;
export type ComposerSentenceAvailableOptionsRetrieveOrderingEnum = typeof ComposerSentenceAvailableOptionsRetrieveOrderingEnum[keyof typeof ComposerSentenceAvailableOptionsRetrieveOrderingEnum];
/**
 * @export
 */
export const ComposerSentenceAvailableOptionsRetrieveStateEnum = {
    Completed: 'completed',
    ComposeLater: 'compose_later',
    ComposeNow: 'compose_now',
    Excluded: 'excluded',
    NeedsFurtherReview: 'needs_further_review',
    Open: 'open',
    ReadyToCompose: 'ready_to_compose'
} as const;
export type ComposerSentenceAvailableOptionsRetrieveStateEnum = typeof ComposerSentenceAvailableOptionsRetrieveStateEnum[keyof typeof ComposerSentenceAvailableOptionsRetrieveStateEnum];
/**
 * @export
 */
export const ComposerSentenceBulkActionCreateOrderingEnum = {
    Id: '-id',
    LastEdited: '-last_edited',
    Owner: '-owner',
    Id2: 'id',
    LastEdited2: 'last_edited',
    Owner2: 'owner'
} as const;
export type ComposerSentenceBulkActionCreateOrderingEnum = typeof ComposerSentenceBulkActionCreateOrderingEnum[keyof typeof ComposerSentenceBulkActionCreateOrderingEnum];
/**
 * @export
 */
export const ComposerSentenceBulkActionCreateStateEnum = {
    Completed: 'completed',
    ComposeLater: 'compose_later',
    ComposeNow: 'compose_now',
    Excluded: 'excluded',
    NeedsFurtherReview: 'needs_further_review',
    Open: 'open',
    ReadyToCompose: 'ready_to_compose'
} as const;
export type ComposerSentenceBulkActionCreateStateEnum = typeof ComposerSentenceBulkActionCreateStateEnum[keyof typeof ComposerSentenceBulkActionCreateStateEnum];
/**
 * @export
 */
export const ComposerSentenceListOrderingEnum = {
    Id: '-id',
    LastEdited: '-last_edited',
    Owner: '-owner',
    Id2: 'id',
    LastEdited2: 'last_edited',
    Owner2: 'owner'
} as const;
export type ComposerSentenceListOrderingEnum = typeof ComposerSentenceListOrderingEnum[keyof typeof ComposerSentenceListOrderingEnum];
/**
 * @export
 */
export const ComposerSentenceListStateEnum = {
    Completed: 'completed',
    ComposeLater: 'compose_later',
    ComposeNow: 'compose_now',
    Excluded: 'excluded',
    NeedsFurtherReview: 'needs_further_review',
    Open: 'open',
    ReadyToCompose: 'ready_to_compose'
} as const;
export type ComposerSentenceListStateEnum = typeof ComposerSentenceListStateEnum[keyof typeof ComposerSentenceListStateEnum];


/**
 * MetacellAuthApi - axios parameter creator
 * @export
 */
export const MetacellAuthApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        metacellAuthLoginRetrieve: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/metacell_auth/login/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication tokenAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        metacellAuthLogoutRetrieve: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/metacell_auth/logout/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication tokenAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MetacellAuthApi - functional programming interface
 * @export
 */
export const MetacellAuthApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MetacellAuthApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async metacellAuthLoginRetrieve(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Login>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.metacellAuthLoginRetrieve(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MetacellAuthApi.metacellAuthLoginRetrieve']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async metacellAuthLogoutRetrieve(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Logout>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.metacellAuthLogoutRetrieve(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MetacellAuthApi.metacellAuthLogoutRetrieve']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * MetacellAuthApi - factory interface
 * @export
 */
export const MetacellAuthApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MetacellAuthApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        metacellAuthLoginRetrieve(options?: RawAxiosRequestConfig): AxiosPromise<Login> {
            return localVarFp.metacellAuthLoginRetrieve(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        metacellAuthLogoutRetrieve(options?: RawAxiosRequestConfig): AxiosPromise<Logout> {
            return localVarFp.metacellAuthLogoutRetrieve(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MetacellAuthApi - object-oriented interface
 * @export
 * @class MetacellAuthApi
 * @extends {BaseAPI}
 */
export class MetacellAuthApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MetacellAuthApi
     */
    public metacellAuthLoginRetrieve(options?: RawAxiosRequestConfig) {
        return MetacellAuthApiFp(this.configuration).metacellAuthLoginRetrieve(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MetacellAuthApi
     */
    public metacellAuthLogoutRetrieve(options?: RawAxiosRequestConfig) {
        return MetacellAuthApiFp(this.configuration).metacellAuthLogoutRetrieve(options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * PublicApi - axios parameter creator
 * @export
 */
export const PublicApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * KnowledgeStatement that only allows GET to get the list of ConnectivityStatements
         * @param {Array<string>} [destinationUris] Multiple values may be separated by commas.
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {Array<string>} [originUris] Multiple values may be separated by commas.
         * @param {Array<string>} [populationUris] Multiple values may be separated by commas.
         * @param {Array<string>} [viaUris] Multiple values may be separated by commas.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        composerKnowledgeStatementList: async (destinationUris?: Array<string>, limit?: number, offset?: number, originUris?: Array<string>, populationUris?: Array<string>, viaUris?: Array<string>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/composer/knowledge-statement/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication tokenAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication cookieAuth required

            if (destinationUris) {
                localVarQueryParameter['destination_uris'] = destinationUris.join(COLLECTION_FORMATS.csv);
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (originUris) {
                localVarQueryParameter['origin_uris'] = originUris.join(COLLECTION_FORMATS.csv);
            }

            if (populationUris) {
                localVarQueryParameter['population_uris'] = populationUris.join(COLLECTION_FORMATS.csv);
            }

            if (viaUris) {
                localVarQueryParameter['via_uris'] = viaUris.join(COLLECTION_FORMATS.csv);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PublicApi - functional programming interface
 * @export
 */
export const PublicApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PublicApiAxiosParamCreator(configuration)
    return {
        /**
         * KnowledgeStatement that only allows GET to get the list of ConnectivityStatements
         * @param {Array<string>} [destinationUris] Multiple values may be separated by commas.
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {Array<string>} [originUris] Multiple values may be separated by commas.
         * @param {Array<string>} [populationUris] Multiple values may be separated by commas.
         * @param {Array<string>} [viaUris] Multiple values may be separated by commas.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async composerKnowledgeStatementList(destinationUris?: Array<string>, limit?: number, offset?: number, originUris?: Array<string>, populationUris?: Array<string>, viaUris?: Array<string>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedKnowledgeStatementList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.composerKnowledgeStatementList(destinationUris, limit, offset, originUris, populationUris, viaUris, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PublicApi.composerKnowledgeStatementList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * PublicApi - factory interface
 * @export
 */
export const PublicApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PublicApiFp(configuration)
    return {
        /**
         * KnowledgeStatement that only allows GET to get the list of ConnectivityStatements
         * @param {Array<string>} [destinationUris] Multiple values may be separated by commas.
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {Array<string>} [originUris] Multiple values may be separated by commas.
         * @param {Array<string>} [populationUris] Multiple values may be separated by commas.
         * @param {Array<string>} [viaUris] Multiple values may be separated by commas.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        composerKnowledgeStatementList(destinationUris?: Array<string>, limit?: number, offset?: number, originUris?: Array<string>, populationUris?: Array<string>, viaUris?: Array<string>, options?: RawAxiosRequestConfig): AxiosPromise<PaginatedKnowledgeStatementList> {
            return localVarFp.composerKnowledgeStatementList(destinationUris, limit, offset, originUris, populationUris, viaUris, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PublicApi - object-oriented interface
 * @export
 * @class PublicApi
 * @extends {BaseAPI}
 */
export class PublicApi extends BaseAPI {
    /**
     * KnowledgeStatement that only allows GET to get the list of ConnectivityStatements
     * @param {Array<string>} [destinationUris] Multiple values may be separated by commas.
     * @param {number} [limit] Number of results to return per page.
     * @param {number} [offset] The initial index from which to return the results.
     * @param {Array<string>} [originUris] Multiple values may be separated by commas.
     * @param {Array<string>} [populationUris] Multiple values may be separated by commas.
     * @param {Array<string>} [viaUris] Multiple values may be separated by commas.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublicApi
     */
    public composerKnowledgeStatementList(destinationUris?: Array<string>, limit?: number, offset?: number, originUris?: Array<string>, populationUris?: Array<string>, viaUris?: Array<string>, options?: RawAxiosRequestConfig) {
        return PublicApiFp(this.configuration).composerKnowledgeStatementList(destinationUris, limit, offset, originUris, populationUris, viaUris, options).then((request) => request(this.axios, this.basePath));
    }
}



